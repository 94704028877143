import { useState, useEffect } from "react";
import styles from "./ProgressiveImg.module.css";

/**
* accepts 3 images (cover, desktop, tablet, mobile), first shows `cover`, then shows `desktop`, `tablet` or `mobile` depending on the size of the screen.
* @NOTE `desktop`, `tablet` and `mobile` are optional, you need to pass at least one of them.
* @Example pass an object like this -> { desktop: files.location, cover: files.cover, mobile: files.low }
* */
export const ProgressiveImg = (props) => {
  const [src, setSrc] = useState(props.cover);
  useEffect(()=>{setSrc(props.cover)},[props.location])
  const devices = ["desktop", "tablet", "mobile"];

  function getDevice(currentDevice) {
    const foundDevice = props[currentDevice];

    if (!foundDevice) {
      // if no image for current device was passed ...
      // ... first check if an image was passed for smaller devices, use it ...
      // ... otherwise if there was an image for bigger device use that.
      const idx = devices.indexOf(currentDevice);
      let found = getSmallerImage(idx);
      if (found) return found;
      found = getLargerImage(idx);
      if (found) return found;
    }

    return foundDevice;
  }

  function getSmallerImage(idx) {
    while (++idx < devices.length) {
      const value = props[devices[idx]];
      if (value) return value;
    }
  }

  function getLargerImage(idx) {
    while (idx--) {
      const value = props[devices[idx]];
      if (value) return value;
    }
  }

  useEffect(() => {
    if(props.cover)setSrc(props.cover)

    const desktopMediaQuery = window.matchMedia("(min-width: 768px)");
    const tabletMediaQuery = window.matchMedia("(min-width: 500px)");


    const newSrc = desktopMediaQuery.matches
      ? getDevice("desktop")
      : tabletMediaQuery.matches
      ? getDevice("tablet")
      : getDevice("mobile");

    if(props.cover){
      const img = new Image();
      img.src = newSrc;
      img.onload = () => {
        setSrc(newSrc);
      };
    }else{
        setSrc(newSrc);
    }
  }, [props.cover,props.location]);

  return (
    <img
      alt=""
      src={src}
      className={`${styles.img} ${src === props.cover ? styles.blur : ''} ${props.className ? props.className : ''}`.trim()}
    />
  );
};
