// @ts-ignore
import moment from 'moment-jalaali';
import {toast} from "react-toastify"
import Logo from "../Assets/logo.svg"
moment.loadPersian({ usePersianDigits: true });

/**
 * converts the number to string and adds colons,
 * for example converts 1200000 to 1,200,000
 * */
export const formatMoney = (price: number): string =>
  new Intl.NumberFormat("irr").format(price);

type Files = { cover?: string; dir?: string; location?: string; low?: string; medium?: string; };

/**
 * gets the product and returns desktop, mobile and cover image
 * */
export const getImages = (files: Files = { location: Logo }) => {
  return {
    desktop: files.location ?? null,
    cover: files.cover ?? null,
    tablet: files.medium ?? null,
    mobile: files.low ?? null,
    // mobile: files.low ? BASE_URL + files.dir + files.low : null,
  };
};


/**
 * @returns a random id
 * */
export const getRandomId = () => {
  return Math.random().toString(36).substring(2, 9)
};

/**
 * returns jalali date, like '۱۴۰۰/۰۹/۱۴'
 * @params accepts a date as string, like 'Mon Dec 06 2021 10:17:50 GMT+0330 (Iran Standard Time)'
 * */
export const formatDate = (date: string) => {
  return date ? moment(date).format('jYYYY/jM/jD') : null;
}

/**
 * serialize the inputs and add them together
 * @params accepts any number of inputs of any type
 * */
export function serialize(...args: any[]) {
  return args.reduce((acc, item) => {
    return acc + JSON.stringify(item);
  }, [''])
}

/**
 * * use this to send object or arrays with formData
 * @params accepts object, array, string, date as the first argument
 * @important JUST PASS YOUR DATA AS THE FIRST ARGUMENT AND IGNORE THE SECOND AND THE THIRD ARGUMENT (those are used for internal calls as recursion)
 * @see https://stackoverflow.com/questions/61955832/unclear-behaviour-of-formdata-sending-an-array-of-files-to-api
 * @see https://stackoverflow.com/questions/16104078/appending-array-to-formdata-and-send-via-ajax
 * */
export function convertModelToFormData(val: any, formData = new FormData(), namespace = '') {
  if ((typeof val !== 'undefined') && (val !== null)) {
    if (val instanceof Date) {
      formData.append(namespace, val.toISOString());
    } else if (val instanceof Array) {
      val.forEach((element, idx) => (
        convertModelToFormData(element, formData, namespace + `[${idx}]`)
      ))
    } else if (typeof val === 'object' && !(val instanceof File)) {
      for (let propertyName in val) {
        if (val.hasOwnProperty(propertyName)) {
          convertModelToFormData(val[propertyName], formData, namespace ? namespace + '[' + propertyName + ']' : propertyName);
        }
      }
    } else {
      formData.append(namespace, val.toString());
    }
  }
  return formData;
}

/**
 * handles the two steps of window.fetch and returns response and body
 * */
export async function oneStepFetch(url: string, options: any, showError: boolean, showSuccess: boolean) {
  // const fetchJSON = (url :string, options:any ,  showError:boolean , showSuccess:boolean) => {

  const fetchOptions = { ...options, headers: { 'Content-Type': 'application/json', ...options.headers } }

  const DisplayError = showError ?? true
  const DisplaySuccess = showSuccess ?? true

  return fetch(url, fetchOptions)
    .then(response => {
      return getResponseBody(response).then((body: any) => ({
        response,
        body
      }))
    })
    .then((response) => checkStatus(response, DisplaySuccess))
    .catch(err => {

      if (typeof err === 'undefined') {
        toast.error('Unexpected Error!')
        return
      }

      if (err?.body?.message?.length !== 0 && err?.body?.message && DisplayError && err?.response?.status !== 401) {
        if (typeof err.body.message !== 'string') {
          Object.values(err.body.message).map((msg: any) => {
            toast.error(msg[0])
          })
        } else {
          toast.error(err.body.message)
        }
      }

      if (err?.message === 'Failed to fetch') {
        toast.error('مشکلی پیش امده لطفا پس از بررسی اینترنت مجددا تلاش کنید.')
      } else {
        throw err
      }

    })

  //   }
  //   return fetchJSON





  // const res = await fetch(url, options);


  // return {body: await res.json(), response: res};
}


// JSON parse
const getResponseBody = (response: any) => {
  const contentType = response.headers.get('content-type')
  return contentType && contentType.indexOf('json') >= 0
    ? response.clone().text().then(tryParseJSON)
    : response.clone().text()
}

const tryParseJSON = (json: string) => {
  if (!json) {
    return null
  }

  try {
    return JSON.parse(json)
  } catch (e) {
    throw new Error(`Failed to parse unexpected JSON response: ${json}`)
  }
}
//

// manage Error


class ResponseError {
  constructor(status: number, response: any, body: any) {
    this.name = 'ResponseError'
    this.status = status
    this.response = response
    this.body = body
  }
  name: string
  status: any
  response: any
  body: any
}


const checkStatus = ({ response, body }: any, showSuccess: boolean) => {
  if (response.ok) {
    if ((response?.status === 201 || response?.status === 202 || response?.status === 206) && showSuccess) {
      toast.success(body.message)
    }
    return { response, body }
  } else {
    throw new ResponseError(response.status, response, body)
  }
}


export function isValid(obj1: object, obj2: object): boolean {
  const Keys = Object.keys(obj1)
  return !Keys.length ? true : Keys.some((currentValue) => {
    // @ts-ignore
    if (typeof obj1[currentValue] === 'object' && obj1[currentValue] !== null && obj1[currentValue].constructor === Object) {// @ts-ignore
      return isValid(obj1[currentValue], obj2[currentValue])
    } else { // @ts-ignore
      if (obj1[currentValue].constructor === Array) {// @ts-ignore
        return obj2[currentValue].length > obj2[currentValue].length >= obj1[currentValue].length
      }
      // @ts-ignore
      return typeof obj1[currentValue] == typeof obj2[currentValue]
    }
  })
}

export function reonce(inp: () => void): () => void {
  let clicked = false
  return function once() {
    if (!clicked) {
      clicked = true
      if (typeof inp === 'function') {
        inp()
      }
    }
  }
}

export function fetchdebebounce(inp: () => void): (imp: any) => void {
  let boundery: any
  return function (imp: any) {
    if (!boundery) {
      boundery = setTimeout(inp.bind(null, imp), 1000)
    } else {
      clearTimeout(boundery)
      boundery = setTimeout(inp.bind(null, imp), 1000)
    }
  }
}

export async function reSync() {
  const cach = await caches.keys()
  cach.map(key => caches.delete(key))
  // unregister service worker
  // navigator.serviceWorker.getRegistrations().then(function(registrations) {
  //  for(let registration of registrations) {
  //     registration.unregister()
  //   } })
  window.location.reload()
}
export const isInternalURL = (to: string) => {
  try {
    const url = new URL(to, window.location.origin);
    return url.hostname === window.location.hostname;
  } catch {
    return false;
  }
};

// get date of react-multi-date-picker
export const getDatePicker = (date: any) => {
  const dates = new Date(date).toLocaleString('en-us').split(',')[0].split('/')
  const hour = date.hour
  const minute = date.minute
  const second = date.second
  const day = dates[1]
  const month = dates[0]
  const year = dates[2]
  const finalDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`
  return finalDate
};
