import PhoneInput from "react-phone-input-2";
import React from "react";
import {ChangeCompanyPhoneHandler, ChangeInfoState} from "../../../Provider/ChangeInfo/ChangeInfo";
import 'react-phone-input-2/lib/style.css'
import {useConfig} from "../../../Provider/ConfigProvider/ConfigProvider";
import styles from './components.module.css'

export default function Phone({setExpanded}: any) {

    const syncApp = useConfig()
    const {companyPhone} = ChangeInfoState();
    const {changeCompanyPhoneHandler} = ChangeCompanyPhoneHandler()

    return (
        <div className={styles.phoneContainer} dir='ltr'>
            <PhoneInput
                buttonStyle={{
                    border: 'none',
                }}
                countryCodeEditable={false}
                country={'om'}
                value={(companyPhone !== '' ? companyPhone : null) || syncApp?.user?.supplier_info?.phone_number}
                onChange={(phone: any) => changeCompanyPhoneHandler(phone)}
                enableSearch={true}
            />
        </div>
    )
}
