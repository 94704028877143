import {useEffect, useState} from "react";
import {isValid} from "../helpers/helpers";
import {Fetch} from "../Provider/api"
import {useLang} from "../Provider/langProvider/LangProvider";

const useDefaultFetch = (url, initialState, body = {}, options,defaultfetch=false) => {
  const fetcher = defaultfetch?window.fetch:Fetch()
  const lang = useLang()
  const [state, setState] = useState({
    loading: true,
    error: null,
    data: initialState,
  });
  let global
  if (options?.global?.get)global = options.global.get

  useEffect(() => {
    const BASE_URL = process.env.REACT_APP_BASE_URL + "/api/";
    const myAbortController = new AbortController();
    if (!options?.cancel){

      (async function () {
      try {

        if (global) {
          return setState({error:null , loading: false, data: global});
        }else{
          setState({error:null , loading: true, data: initialState});
        }

        const result = await fetcher(BASE_URL + url, {
          headers: {...options?.headers,lang:lang},
          method: 'post',
          body: body,
          signal: myAbortController.signal
        })
        if (options?.cancel){return}
        if (isValid(initialState, result.body)) {
          if (!!options?.global?.set) {
            // options.global.set(result.body)
          }
          setState({error: null, loading: false, data: result.body});
        } else if(!global){
          setState({error: {message:'Bad Response From Server'} , loading: false, data: global??initialState});
          console.log('usedefault fetch invalid', initialState, result.body)

        }
      } catch (err) {
        console.log(err);
        if(global) return setState({data: global , loading: false, error: err});
      } finally {
        setState(prev=>{return { ...prev , loading: false,}})
      }
    })();
    }
    else {
      setState(prev=>{return { ...prev , error:null , loading: false,}})
    }

    // return () => myAbortController.abort();
  }, [url, lang, body, global]);

  return {...state, setState,setGlobalState:options?.global?.set};
};

export default useDefaultFetch;
