
const lang = {
  fa: {
    dir: "rtl",
    digitalCode: {
      enterCode: "کد را وارد کنید",
      confirmationSent: (input) => (
        <>کد تایید فرستاده شده به {input} را وارد کنید.</>
      ),
      email: "ایمیل",
      mobile: "موبایل",
      code: "کد",
      login: "بررسی و ورود",
      resend1: "ارسال مجدد کد یکبار مصرف",
      resend2: "پس از",
      dialogTitle: "کد موقت فرستاده شده است!",
      dialogMessageEmail: "لطفا ایمیل خود را بررسی کنید",
      dialogMessageMobile: "لطفا موبایل خود را بررسی کنید",
      dialogButtonText: "باشه",
    },
    signUpEmail: {
      notValidEmail: "ایمیل باید یک آدرس ایمیل معتبر باشد.",
      notValidMobile: "شماره تلفن باید یک شماره تلفن معتبر باشد.",
      coupleButtonEmail: "ایمیل",
      coupleButtonMobile: "شماره تلفن",
      loginSignUp: "ورود / ثبت نام",
      login: "ورود",
      or: "یا",
      privacyPolicy:() => (
        <>
          <p style={{marginTop:'0'}}>
            کاربر گرامی لطفاً موارد زیر را جهت استفاده بهینه از خدمات نرم افزار چشم عقاب به دقت ملاحظه فرمایید .
        ورود کاربران به نرم افزار چشم عقاب به معنای آگاه بودن و پذیرفتن شرایط و قوانین و همچنین نحوه استفاده از سرویس‌ها و خدمات چشم عقاب است. توجه داشته باشید که ثبت سفارش نیز در هر زمان به معنی پذیرفتن کامل کلیه شرایط و قوانین چشم عقاب از سوی کاربر است. لازم به ذکر است شرایط و قوانین مندرج، جایگزین کلیه توافقهای قبلی محسوب میشود.
          </p>
          <p>
            قوانین عمومی :<br/>
        توجه داشته باشید کلیه اصول و رویه های چشم عقاب منطبق با قوانین جمهوری اسلامی ایران، قانون تجارت الکترونیک و قانون
        حمایت از حقوق مصرف کننده است و متعاقبا کاربر نیز موظف به رعایت قوانین مرتبط با کاربر است. در صورتی که در قوانین 
        مندرج، رویه ها و سرویس‌های چشم عقاب تغییراتی در آینده ایجاد شود، در همین صفحه منتشر و به روز رسانی می شود و شما 
        توافق میکنید که استفاده مستمر شما از برنامه به معنی پذیرش هرگونه تغییر است.
          </p>
          <p>
            
        تعریف مشتری یا کاربر :  <br/>
        مشتری یا کاربر به شخصی گفته میشود که با اطلاعات کاربری خود که در فرم ثبت نام درج کرده است، به ثبت سفارش یا هرگونه 
        استفاده از خدمات چشم عقاب اقدام کند. 
        همچنین از آنجا که چشم عقاب یک نرم افزار خرده فروشی آنلاین است، طبق قانون تجارت الکترونیک مشتری یا مصرف کننده هر 
        شخصی است که به قصدی جز تجارت یا شغل حرفه‌ای به خرید کالا یا خدمات اقدام کند. 
          </p>
          <p>
            تعریف فروشنده و مسولیت فروشنده : <br/>
        فروشنده به نرم افزار چشم عقاب گفته میشود که با فروش محصولات خود به کاربر اقدام مینماید. 
        مسئولیتهای مربوط به کیفیت، قیمت، محتوا، شرایط و همچنین خدمات پس از فروش محصول بر عهده فروشنده است. 
        خدمات پس از فروش چشم عقاب تنها درصورتی درخواست مشتری مبنی بر بازگرداندن کالای فروشنده را میپذیرد که عیبی به کالا 
        ایراد نشده باشد. 
          </p>
          <p>
          حریم خصوصی : <br/>
        چشم عقاب با تاکید بر احترامی که برای حریم شخصی کاربران قائل است، برای خرید، برای پردازش و ارسال سفارش، اطلاعاتی 
        مانند آدرس، شماره تلفن مورد نیاز است  
        یادآوری میشود تلفنهایی که مشتری در پروفایل خود ثبت میکند، تنها تلفنهای رسمی و مورد تایید مشتری است و تمام مکاتبات و 
        پاسخهای شرکت از طریق آنها صورت میگیرد. 
        بنابراین درج آدرس، شماره تماسهای همراه و ثابت توسط مشتری، به منزله مورد تایید بودن صحت آنها است و در صورتی که 
        موارد فوق به صورت صحیح یا کامل درج نشده باشد، چشم عقاب جهت اطمینان از صحت و قطعیت ثبت سفارش میتواند از 
        مشتری، اطلاعات تکمیلی و بیشتری درخواست کند. 
        مشتریان میتوانند نام، آدرس و تلفن شخص دیگری را برای تحویل گرفتن سفارش وارد کنند چشم عقاب تنها برای ارسال همان 
        سفارش، از این اطلاعات استفاده خواهد کرد. 
          </p>
          <p>
          ثبت، پردازش و ارسال سفارش :<br/> 
          - روز کاری به معنی روز شنبه تا پنج شنبه هر هفته، به استثنای تعطیلات عمومی در ایران است . <br/>
          - ساعت کاری این فروشگاه از ساعت 8 صبح الی 6 بعدازظهر است. <br/>
        کلیه سفارشهای ثبت شده در طول شبانه روز در صورتی که در ساعات کاری فروشگاه ثبت شده باشد در همان روز پردازش میگردند و سفارشاتی که در ساعات کاری ثبت 
        نگردد در روز کاری بعدی پردازش میگردند .<br/> چشم عقاب به مشتریان خود  
        فاکتور تمام محصولات را در محل تحویل کالا به تحویل گیرنده تحویل می‌دهد. 
        محصولاتی که وزن ثابت ندارند با توجه به تغییرات در وزن کالا فاکتور جدید صادر و قبل از ارسال به مشتری اطلاع داده میشود.
          </p>
          <p>
          نحوه پرداخت صورت حساب :<br/>
        کلیه پرداختهای مشتری در زمان تحویل کالا و یا از طریق درگاه پرداخت به صورت آنلاین انجام میگیرد.در صورت تغییر در پرداخت اطلاع رسانیهای لازم انجام میگردد. 
          </p>
        </>
      ),
      privacyPolicyModalTitle:"قوانین و مقررات",
      acceptTerms:(Button , handleClickOpen ) => (
        <> 
          <Button 
            style={{padding:"0",justifyContent:"flex-start" , color:"blue",textDecoration:"underline" , fontSize:"1em" , textTransform:"unset"}} 
            onClick={handleClickOpen('paper')}>
              قوانین و مقررات
          </Button>
          <label htmlFor='terms'> را مطالعه کردم و قبول دارم</label>
        </>
      ),
      closePrivacyPolicy:"قوانین و مقررات را مطالعه کردم و قبول دارم",
      isTemrsChecked:"پذیرش قوانین و مقررات الزامی است"
    },
    enterPassword: {
      dialogTitle: "حساب کاربری شما پیدا شد!",
      dialogMessage: "لطفا در صفحه ی بعدی رمزتان را وارد کنید",
      dialogButton: "باشه",
      title: "رمز حساب کاربری تان را وارد کنید",
      message: "رمزتان را وارد کنید",
      label: "رمز",
      login: "بررسی و ورود",
      forgetPass: "آیا رمز عبور خود را فراموش کرده اید؟",
    },
    social: {
      google: "ثبت نام با گوگل",
      facebook: "ثبت نام با فیسبوک",
    },
    desktopLoginContainer: {
      make: () => (
        <h2>
          یک رانندگی به یادماندنی <br />
          بساز
        </h2>
      ),
      description1:
        "به واسطه فیلد کاری متفاوت، من تجربه استفاده از خدمات چشم عقاب رو نداشتم ولی هر کدوم از بچه های تیم‌ فنی‌مون که یک محصول از مجموعه چشم عقاب تهیه کرده،‌ هم رضایت بالایی داشتند هم آموزش ها براشون خیلی کاربردی بوده. تبریک میگم به حسن یوسفی عزیز برای وب سایت ارزشمندی که خلق کرده",
      description2:"رانندگی شغل نیست عشق است که با کمک آموزش‌های جذاب و کاربردی چشم عقاب، میتوانید عاشق تر باشید.",
      description3:"چشم عقاب شاید تنها ترین مجموعه در ایران باشد، که آموزش های کاربردی و بروز و محصولات متنوع و به صرفه متناسب با نیاز بازار داخلی و جهانی ارائه میده! دمتون گرم",
      name1: "علی معینی",
      name2: "حامد زمانی",
      name3: "محمد روشنی",
      subName1: "یک کاربر",
    },
    mobileLoginContainer: {
      crPass: "کد را وارد کن",
      digiCod: "وارد کردن کد یکبار مصرف",
      enPass: "وارد کردن رمز عبور",
      signUp: "ورود / ثبت نام",
    },
    setting: {
      mainTitle: "پروفایل",
      tabSetting: "تنظیمات",
      tabUserInformation: "اطلاعات کاربر",
      settingChild: {
        title: "تنظیمات",
        changeEmailTitleBar: "ایمیل",
        changeEmailTitle: "تغییر ادرس ایمیل",
        changeEmailLabel: "ایمیل",
        changeEmailPlaceholder:'ایمیل جدید خود را وارد کنید.',
        digitalCodeLabel: "کد",
        digitalCodeSend: "ارسال",
        changePhoneNumberTitleBar: "شماره تلفن",
        changePhoneNumberTitle: "تغییر یا ایجاد شماره تلفن",
        changePhoneNumberLabel: "شماره تلفن",
        changePasswordTitleBar: "تغییر کلمه عبور",
        changePasswordTitle: "تغییر کلمه عبور جدید",
        changePasswordTitleSubText: () => (
          <>
            کلمه عبور جدید باید با <br /> کلمه عبور قبلی تان متفاوت باشد
          </>
        ),
        validationMatch: "یکسان بودن رمز",
        validationNumber: "شامل عدد بودن",
        validationUppercase: "شامل کلمات بزرگ",
        validationLowercase: "شامل کلمات کوچک",
        validationSpecial: "دارای عبارات خاص",
        previousPassword: "کلمه عبور قبلی",
        password: "کلمه عبور",
        confirmPassword: "تایید کلمه عبور",
        submit: "ارسال",
        resetForm: "پاک کردن فرم",
        generatePassword: "ایجاد پسورد قوی",
        passwordStrength: "قدرت کلمه عبور",
      },
      userInformation: {
        title: "اطلاعات کاربر",
        submit: "ارسال",
        personalInfo: {
          removeDialogMessage1:'آیا شما مطمئن هستید',
          removeDialogMessage2:'که می خواهید عکستان را پاک کنید؟',
          removeDialogButton: "حذف",
          profileImage: "تصویر پروفایل",
          name: "نام",
          username: "نام کاربری",
          inviter: "کد دعوت",
          invitationCode: "کد دعوت",
          placeholderInviter: "کد دعوتتان را وارد کنید",
          placeholderInvitationCode: "در صورتی که کد دعوت دارید وارد کنید.",
          placeholderName: "نام خود را وارد کنید",
          placeholderUsername: "نام کاربری خود را وارد کنید",
          position: "موقعیت شغلی",
          option1: "مالک خودرو",
          option2: "راننده",
          option3: "تعمیر کار",
          option4: "فروشنده قطعات",
          option5: "سایر",
          dialogButton: "ارسال",
        },
        logout:"خروج از حساب کاربری"
      },
    },
    messages: {
      emailOrPhoneNumberEmpty: "فیلد ایمیل یا شماره تلفن خالی است.",
      emailEmpty: "فیلد ایمیل خالی است",
      phoneNumberEmpty: "فیلد شماره تلفن خالی است",
      phoneNumberMustBeValid: "شماره تلفن باید یک شماره تلفن معتبر باشد.",
      emailMustBeValid: "ایمیل باید یک آدرس ایمیل معتبر باشد.",
      phoneNumberContainAnInvalidNumber:
        "فیلد شماره تلفن شامل شماره نامعتبر است.",
      emailOrPhoneNumberRequired: "ایمیل یا شماره تلفن الزامی است",
      invalidGrant: "نا معتبر",
      usernameRequired: "فیلد نام کاربری الزامی است.",
      passwordRequired: "فیلد رمز عبور الزامی است.",
      successfullyLogout: "شما با موفقیت خارج شدید.",
      unauthenticated: "غیر مجاز.",
      verificationCodeSentToOldEmailForVerifyIt:
        "کد تایید به ایمیل قبلی فرستاده شد.",
      verificationCodeSentToNewEmailForVerifyIt:
        "کد تایید به ایمیل جدید فرستاده شد.",
      emailChangedSuccessful: "ایمیل با موفقیت تغییر کرد.",
      anUnexpectedErrorOccured: "یک خطای غیر منتظره!",
      verificationCodeIsInvalid: "کد تایید نا معتبر",
      theEmailHasAlreadyBeenTaken: "ایمیل قبلا گرفته شده است.",
      yourInformationUpdatedSuccessful: "اطلاعات شما با موفقیت تغییر کرد.",
      youShouldLogin: "شما اول باید وارد شوید.",
      usernameHasAlreadyBeenTaken: "نام کاربری قبلا گرفته شده است.",
      yourPasswordUpdatedSuccessful: "کلمه عبور با موفقیت تغییر کرد.",
      previousPasswordRequired: "کلمه عبور قبلی نا معتبر است.",
      verificationCodeSentToOldPhoneNumberForVerifyIt:
        "کد تایید به شماره تلفن قبلی ارسال شد.",
      verificationCodeSentToNewPhoneNumberForVerifyIt:
        "کد تایید به شماره تلفن جدید ارسال شد.",
      phoneNumberChangedSuccessful: "شماره تلفن با موفقیت تغییر کرد.",
      phoneNumberHasAlreadyBeenTaken: "شماره تلفن قبلا گرفته شده است.",
      TheSelectedInviterIsInvalid: "کد دعوت صحیح نمی باشد.",
      networkError: "مشکلی پیش امده لطفا پس از بررسی اینترنت مجددا تلاش کنید.",
    },
    nearbymapsearch: {
      namayandegi: "نمایندگی ها",
      zoo: "باغ وحش",
      airport: "فرودگاه",
      atm: "خود پرداز",
      bank: "بانک",
      bus: "ایستگاه اتوبوس",
      cafe: "کافه",
      doctor: "دکتر",
      gas_station: "جایگاه سوخت",
      hospital: "بیمارستان",
      hotel: "هتل",
      mosque: "مسجد",
      museum: "موزه",
      park: "پارک",
      parking: "پارکینگ",
      pharmacy: "داروخانه",
      police: "ایستگاه پلیس",
      rent_car: "اجاره ماشین",
      restaurant: "رستوران",
      store: "فروشگاه",
      superMarket: "سوپر مارکت",
      train: "قطار",
      travel: "مسافرت",
    },
    profile: {
      checklists: "چک لیست ها",
      Finances: "حسابداری",
      username: "علی معینی",
      mobileNum: "09130173099",
      changeTruckBrand: "تغییر برند خودرو:",
      changBrand: "تغییر برند",
      brands: ["اسکانیا", "ولو", "رنو", "مان", "بنز"],
      myActivities: "فعالیت‌های من:",
      travels: "سفرها",
      purcheses: "خریدها",
      posts: "پست‌ها",
      favorites: "علاقه‌مندی‌ها",
      comments: "نظرات",
      eagleEyes: "چشم‌عقابی‌ها",
      messages: "پیام‌ها",
      setting: "تنظیمات",
      myPayments: "پرداخت‌های من",
      discounts: "فرصت‌ها و تخفیف‌ها",
      inviteYourFriends:
        "دوستان خود را به جمع چشم عقابی ها دعوت کنید و 5 امتیاز هدیه بگیرید.",
    },
    sidebar: {
      version: "شماره نسخه 0.2",
      mainPage: "صفحه اصلی",
      map: "نقشه",
      diagnose: "عیب یابی",
      shop: "فروشگاه",
      account: "حساب کاربری",
      profile: "پروفایل",
      setting: "تنظیمات",
      travels: "سفرها",
      purcheses: "خریدها",
      balance: "موجودی خرید",
      payments: "پرداخت ها",
      favs: "علاقه مندی ها",
      forum: "انجمن ها",
      eagleEyeMembers: "چشم عقابی ها",
      posts: "پست ها",
      comments: "نظرات",
      messages: "پیام ها",
      discounts: "فرصت ها و تخفیف ها",
      aboutUs: "درباره ما",
      critisims: "انتقاد و پیشنهادات",
      support: "پشتیبانی و خدمات مادگستر",
      logout: "خروج",
    },
    problem: {
      problem: "عنوان مشکل",
      desc: "شرح مشکل",
      address: "آدرس متنی",
      send: "ارسال",
      sendLocation: "ارسال موقعیت مکانی به پشتیبانی",
      askForHelp: "درخواست امداد",
      location: "موقعیت مکانی",
      msgRecieved: "!درخواست امداد شما ارسال شد",
      yourTackingCode: (code) => `کد پیگیری شما: ${code}`,
      copyCode: "کپی کردن کد پیگیری",
      trackingCodeSaved: "کد پیگیری شما در کلیپ بورد کپی شد.",
      msgNotReceived: "!پیام ارسال نشد",
      tryAgain: "لطفا دوباره امتحان کنید",
      ok: "باشه",
    },
  },
  en: {
    digitalCode: {
      enterCode: "Enter Digital Code",
      confirmationSent: (input) => <>Confirmation code sent to your {input}</>,
      email: "email",
      mobile: "mobile",
      code: "Digital code",
      login: "Login",
      resend1: "Resend the code",
      resend2: "until another",
      dialogTitle: "Temperory Code Has Been Sent!",
      dialogMessageEmail: "Please check your email",
      dialogMessageMobile: "Please check your mobile",
      dialogButtonText: "Ok",
    },
    signUpEmail: {
      notValidEmail: "The email must be a valid email address.",
      notValidMobile: "The phone number must be a valid phone number.",
      coupleButtonEmail: "E-Mail",
      coupleButtonMobile: "Phone Number",
      loginSignUp: "Login / SignUp",
      login: "Login",
      or: "Or",
      privacyPolicy:() => (
        <>
          <p style={{marginTop:'0'}}>
          Dear user, please carefully consider the following items for optimal use of Eagle Eye software services.
         Entering the Eagle Eye software means being aware of and accepting the terms and conditions as well as how to use the Eagle Eye services. Note that registering an order at any time means full acceptance of all terms and conditions of the Eagle Eye by the user. It should be noted that the terms and conditions set forth are an alternative to all previous agreements.
          </p>
          <p>
          General rules: <br/>
         Note all the principles and procedures of the eagle eye in accordance with the laws of the Islamic Republic of Iran, the law of electronic commerce and the law
         It protects the rights of the consumer and consequently the user is obliged to comply with the laws related to the user. If in the rules
         The contents, procedures and services of Eagle Eye will be changed in the future, will be published and updated on this page and you
         You agree that your continued use of the Program constitutes your acceptance of any such changes.
          </p>
          <p>
            
          Customer or user definition: <br/>
         A customer or user is a person who, with his / her user information entered in the registration form, registers an order or any
         Use eagle eye services.
         Also because Eagle Eye is an online retail application, according to e-commerce law any customer or consumer
         A person who buys goods or services for a purpose other than business or professional occupation.
          </p>
          <p>
          Definition of seller and seller responsibility: <br/>
         The seller is called Eagle Eye software that sells its products to the user.
         Responsibilities related to quality, price, content, conditions as well as after-sales service of the product are the responsibility of the seller.
         Eagle Eye after-sales service only accepts the customer's request to return the seller if there is a defect in the product.
         Not objected.
          </p>
          <p>
          Privacy and Policy: <br/>
        Eagle Eye emphasizes information that respects the privacy of users, to buy, to process and send information
        Like the address, a phone number is required
        It is reminded that the phones that the customer registers in his profile are only official and approved phones of the customer and all correspondences and
        The company's responses are made through them.
        Therefore, the inclusion of the address, mobile and landline contact numbers by the customer, confirms their authenticity and if
        If the above items are not entered correctly or completely, the eagle eye can ensure the accuracy and certainty of order registration from
        Customer, request additional information and more.
        Customers can enter the name, address and phone number of another person to receive the order Eagle Eye just to send the same
        The order will use this information.
          </p>
          <p>
          Order registration, processing and sending: <br/>
          - Working day means Saturday to Thursday of each week, with the exception of public holidays in Iran. <br/>
          - The working hours of this store are from 8 am to 6 pm. <br/>
        All orders placed during the day and night, if registered during store hours, will be processed on the same day, and orders placed during business hours will be processed.
        If not, they will be processed on the next working day. <br/> Eagle's eyes to their customers
        Invoice delivers all products to the recipient at the place of delivery.
        Products that do not have a fixed weight due to changes in the weight of the product, a new invoice is issued and the customer is notified before sending.
          </p>
          <p>
          How to pay the bill: <br/>
         All customer payments are made online at the time of delivery of the goods or through the payment gateway. In case of change in payment, the necessary notifications will be made.
          </p>
        </>
      ),
      privacyPolicyModalTitle:"Privacy Policy",
      acceptTerms:(Button , handleClickOpen ) => (
        <> 
          <label htmlFor='terms'>I have read and agree to the </label>
          <Button 
            style={{padding:"0",justifyContent:"flex-start" , color:"blue",textDecoration:"underline" , fontSize:"1em" , textTransform:"unset"}} 
            onClick={handleClickOpen('paper')}>
              Terms and Service
          </Button>
        </>
      ),
      closePrivacyPolicy:"I read the rules and accept",
      isTemrsChecked:"Acceptance of Terms and Conditions Required"
    },
    enterPassword: {
      dialogTitle: "Your account has been found!",
      dialogMessage: "Please enter your password on the next page",
      dialogButton: "Ok",
      title: "Enter your account password",
      message: "Welcome back",
      label: "Password",
      login: "Login",
      forgetPass: "Forget your password ?",
    },
    social: {
      google: "Sign Up With Google",
      facebook: "Sign Up With Facebook",
    },
    desktopLoginContainer: {
      make: () => (
        <h2>
          Make A Driving<br />
          To Remember
        </h2>
      ),
      description1:
        "Due to the different field of work, I did not have the experience of using the Eagle Eye services, but each of the kids on our technical team, who provided a product from the Eagle Eye collection, were both very satisfied and the training was very practical for them. Congratulations to dear Hassan Yousefi for the valuable website he has created",
      description2:"Driving is not a job, it is love that you can fall in love with with the help of attractive and practical eagle eye trainings.",
      description3:"Eagle Eye is perhaps the only collection in Iran, which offers practical and up-to-date training and various and cost-effective products tailored to the needs of the domestic and global market! Your mouth is hot",
      name1: "Ali Moeini",
      name2: "Hamed Zamani",
      name3: "Mohammad Roshani",
      subName1: "A driver",
    },
    mobileLoginContainer: {
      crPass: "Digital Code",
      digiCod: "Enter Temporary Code",
      enPass: "Enter Password",
      signUp: "Signup/Login",
    },
    setting: {
      mainTitle: "Profile",
      tabSetting: "Setting",
      tabUserInformation: "User Information",
      settingChild: {
        title: "Setting",
        changeEmailTitleBar: "Email",
        changeEmailTitle: "Change your email address here",
        changeEmailLabel: "Email",
        changeEmailPlaceholder:'Enter your new email.',
        digitalCodeLabel: "Digital Code",
        digitalCodeSend: "Send",
        changePhoneNumberTitleBar: "Phone Number",
        changePhoneNumberTitle: "Change your phone number here",
        changePhoneNumberLabel: "Phone Number",
        changePasswordTitleBar: "Change Password",
        changePasswordTitle: "Create new password",
        changePasswordTitleSubText: () => (
          <>
            Your new password must be different from <br /> the previous used
            password
          </>
        ),
        validationMatch: "Passwords match",
        validationNumber: "Has number",
        validationUppercase: "Has uppercase",
        validationLowercase: "Has lowercase",
        validationSpecial: "Has special character",
        previousPassword: "Previous Password",
        password: "Password",
        confirmPassword: "Confirm password",
        submit: "Submit",
        resetForm: "Reset Form",
        generatePassword: "Generate Password",
        passwordStrength: "Password Strength",
      },
      userInformation: {
        title: "User Information",
        submit: "Submit",
        personalInfo: {
          removeDialogMessage1:'Are you sure',
          removeDialogMessage2:'you want to delete your photo?',
          removeDialogButton: "remove",
          profileImage: "Profile Image",
          name: "I am",
          username: "Username",
          inviter: "Inviter",
          invitationCode: "Invitation Code",
          placeholderInviter: "Enter Your Invitation Code",
          placeholderInvitationCode: "Enter the invitation code if you have one",
          placeholderName: "Enter Your Name",
          placeholderUsername: "Enter Your Username",
          position: "Position",
          option1: "Car owner",
          option2: "Driver",
          option3: "Repairman",
          option4: "Seller",
          option5: "Other",
          dialogButton: "Send",
        },
        logout:"Logout"
      },
    },
    messages: {
      emailOrPhoneNumberEmpty: "The email or phone number field is empty.",
      emailEmpty: "The email field is empty.",
      phoneNumberEmpty: "The phone number field is empty.",
      phoneNumberMustBeValid: "The phone number must be a valid phone number.",
      emailMustBeValid: "The email must be a valid email address.",
      phoneNumberContainAnInvalidNumber:
        "The phone number field contains an invalid number.",
      emailOrPhoneNumberRequired: "email or phone number required",
      invalidGrant: "invalid_grant",
      usernameRequired: "The password field is required.",
      passwordRequired: "The password field is required.",
      successfullyLogout: "You have successfully logged out!",
      unauthenticated: "Unauthenticated.",
      verificationCodeSentToOldEmailForVerifyIt:
        "Verification code sent to old email for verify it.",
      verificationCodeSentToNewEmailForVerifyIt:
        "Verification code sent to new email for verify it.",
      emailChangedSuccessful: "email changed successfull.",
      anUnexpectedErrorOccured: "An unexpected error occured!",
      verificationCodeIsInvalid: "The verification_code is invalid.",
      theEmailHasAlreadyBeenTaken: "The email has already been taken.",
      yourInformationUpdatedSuccessful: "Your informations updated successful.",
      youShouldLogin: "you should login then change your information",
      usernameHasAlreadyBeenTaken: "The username has already been taken.",
      yourPasswordUpdatedSuccessful: "Your password updated successful.",
      previousPasswordRequired: "The previous_password is invalid.",
      verificationCodeSentToOldPhoneNumberForVerifyIt:
        "Verification code sent to old phone_number for verify it.",
      verificationCodeSentToNewPhoneNumberForVerifyIt:
        "Verification code sent to new phone_number for verify it.",
      phoneNumberChangedSuccessful: "phone_number changed successfull.",
      phoneNumberHasAlreadyBeenTaken:
        "The phone number has already been taken.",
      TheSelectedInviterIsInvalid: "The selected inviter is invalid.",
      networkError:"There is a problem, please try again after checking the internet."
    },
    dir: "ltr",
    nearbymapsearch: {
      namayandegi: "representatives",
      zoo: "Zoo",
      airport: "Airport",
      atm: "Atm",
      bank: "Bank",
      bus: "Bus",
      cafe: "Cafe",
      doctor: "Doctor",
      gas_station: "Gas station",
      hospital: "Hospital",
      hotel: "Hotel",
      mosque: "Mosque",
      museum: "Museum",
      park: "Park",
      parking: "Parking",
      pharmacy: "Pharmacy",
      police: "Police",
      rent_car: "Rent car",
      restaurant: "Restaurant",
      store: "Store",
      superMarket: "SuperMarket",
      train: "Train",
      travel: "Travel",
    },
    profile: {
      checklists: "Checklists",
      Finances: "Finances",
      username: "Ali Moeini",
      mobileNum: "09130173099",
      changeTruckBrand: "Change Truck Brand:",
      changBrand: "Change Brand",
      brands: ["Scania", "Volvo", "Renault", "Man", "Benz"],
      myActivities: "My Activities:",
      travels: "travels",
      purcheses: "Purcheses",
      posts: "Posts",
      favorites: "Favorites",
      comments: "Comments",
      eagleEyes: "Eagle Eye Members",
      messages: "Messages",
      setting: "Setting",
      myPayments: "My Payments",
      discounts: "Opportunities and Discounts",
      inviteYourFriends:
        "Invite your frineds to Eagle Eye members and get five points.",
    },
    sidebar: {
      version: "Version 0.2",
      mainPage: "Main Page",
      map: "Map",
      diagnose: "Diagnose",
      shop: "Shop",
      account: "Account",
      profile: "Profile",
      setting: "Settings",
      travels: "Tarvels",
      purcheses: "Purcheses",
      balance: "Blance",
      payments: "Payments",
      favs: "Favorites",
      forum: "Froum",
      eagleEyeMembers: "EagleEye Members",
      posts: "Posts",
      comments: "Comments",
      messages: "Messages",
      discounts: "Discounts",
      aboutUs: "About Us",
      critisims: "Critisims",
      support: "Maadgostar Support",
      logout: "Logout",
    },
    problem: {
      problem: "problem",
      desc: "description",
      address: "address",
      send: "send",
      sendLocation: "Send location to support",
      askForHelp: "َAsk for Help",
      location: "Location",
      msgRecieved: "Your help request was recieved!",
      yourTackingCode: (code) => `your tracking code is ${code}`,
      copyCode: "copy tracking code",
      trackingCodeSaved: "Your tracking code was saved to the clip board",
      msgNotReceived: "Your message was not send",
      tryAgain: "Please try again later.",
      ok: "OK",
    },
  },
  ar: {
    digitalCode: {
      enterCode: "Enter Digital Code",
      confirmationSent: (input) => <>Confirmation code sent to your {input}</>,
      email: "email",
      mobile: "mobile",
      code: "Digital code",
      login: "Login",
      resend1: "Resend the code",
      resend2: "until another",
      dialogTitle: "Temperory Code Has Been Sent!",
      dialogMessageEmail: "Please check your email",
      dialogMessageMobile: "Please check your mobile",
      dialogButtonText: "Ok",
    },
    signUpEmail: {
      notValidEmail: "The email must be a valid email address.",
      notValidMobile: "The phone number must be a valid phone number.",
      coupleButtonEmail: "E-Mail",
      coupleButtonMobile: "Phone Number",
      loginSignUp: "Login / SignUp",
      login: "Login",
      or: "Or",
      privacyPolicy:() => (
        <>
          <p style={{marginTop:'0'}}>
          Dear user, please carefully consider the following items for optimal use of Eagle Eye software services.
         Entering the Eagle Eye software means being aware of and accepting the terms and conditions as well as how to use the Eagle Eye services. Note that registering an order at any time means full acceptance of all terms and conditions of the Eagle Eye by the user. It should be noted that the terms and conditions set forth are an alternative to all previous agreements.
          </p>
          <p>
          General rules: <br/>
         Note all the principles and procedures of the eagle eye in accordance with the laws of the Islamic Republic of Iran, the law of electronic commerce and the law
         It protects the rights of the consumer and consequently the user is obliged to comply with the laws related to the user. If in the rules
         The contents, procedures and services of Eagle Eye will be changed in the future, will be published and updated on this page and you
         You agree that your continued use of the Program constitutes your acceptance of any such changes.
          </p>
          <p>
            
          Customer or user definition: <br/>
         A customer or user is a person who, with his / her user information entered in the registration form, registers an order or any
         Use eagle eye services.
         Also because Eagle Eye is an online retail application, according to e-commerce law any customer or consumer
         A person who buys goods or services for a purpose other than business or professional occupation.
          </p>
          <p>
          Definition of seller and seller responsibility: <br/>
         The seller is called Eagle Eye software that sells its products to the user.
         Responsibilities related to quality, price, content, conditions as well as after-sales service of the product are the responsibility of the seller.
         Eagle Eye after-sales service only accepts the customer's request to return the seller if there is a defect in the product.
         Not objected.
          </p>
          <p>
          Privacy and Policy: <br/>
        Eagle Eye emphasizes information that respects the privacy of users, to buy, to process and send information
        Like the address, a phone number is required
        It is reminded that the phones that the customer registers in his profile are only official and approved phones of the customer and all correspondences and
        The company's responses are made through them.
        Therefore, the inclusion of the address, mobile and landline contact numbers by the customer, confirms their authenticity and if
        If the above items are not entered correctly or completely, the eagle eye can ensure the accuracy and certainty of order registration from
        Customer, request additional information and more.
        Customers can enter the name, address and phone number of another person to receive the order Eagle Eye just to send the same
        The order will use this information.
          </p>
          <p>
          Order registration, processing and sending: <br/>
          - Working day means Saturday to Thursday of each week, with the exception of public holidays in Iran. <br/>
          - The working hours of this store are from 8 am to 6 pm. <br/>
        All orders placed during the day and night, if registered during store hours, will be processed on the same day, and orders placed during business hours will be processed.
        If not, they will be processed on the next working day. <br/> Eagle's eyes to their customers
        Invoice delivers all products to the recipient at the place of delivery.
        Products that do not have a fixed weight due to changes in the weight of the product, a new invoice is issued and the customer is notified before sending.
          </p>
          <p>
          How to pay the bill: <br/>
         All customer payments are made online at the time of delivery of the goods or through the payment gateway. In case of change in payment, the necessary notifications will be made.
          </p>
        </>
      ),
      privacyPolicyModalTitle:"Privacy Policy",
      acceptTerms:(Button , handleClickOpen ) => (
        <> 
          <label htmlFor='terms'>I have read and agree to the </label>
          <Button 
            style={{padding:"0",justifyContent:"flex-start" , color:"blue",textDecoration:"underline" , fontSize:"1em" , textTransform:"unset"}} 
            onClick={handleClickOpen('paper')}>
              Terms and Service
          </Button>
        </>
      ),
      closePrivacyPolicy:"I read the rules and accept",
      isTemrsChecked:"Acceptance of Terms and Conditions Required"
    },
    enterPassword: {
      dialogTitle: "Your account has been found!",
      dialogMessage: "Please enter your password on the next page",
      dialogButton: "Ok",
      title: "Enter your account password",
      message: "Welcome back",
      label: "Password",
      login: "Login",
      forgetPass: "Forget your password ?",
    },
    social: {
      google: "Sign Up With Google",
      facebook: "Sign Up With Facebook",
    },
    desktopLoginContainer: {
      make: () => (
        <h2>
          Make A Driving<br />
          To Remember
        </h2>
      ),
      description1:
        "Due to the different field of work, I did not have the experience of using the Eagle Eye services, but each of the kids on our technical team, who provided a product from the Eagle Eye collection, were both very satisfied and the training was very practical for them. Congratulations to dear Hassan Yousefi for the valuable website he has created",
      description2:"Driving is not a job, it is love that you can fall in love with with the help of attractive and practical eagle eye trainings.",
      description3:"Eagle Eye is perhaps the only collection in Iran, which offers practical and up-to-date training and various and cost-effective products tailored to the needs of the domestic and global market! Your mouth is hot",
      name1: "Ali Moeini",
      name2: "Hamed Zamani",
      name3: "Mohammad Roshani",
      subName1: "A driver",
    },
    mobileLoginContainer: {
      crPass: "Digital Code",
      digiCod: "Enter Temporary Code",
      enPass: "Enter Password",
      signUp: "Signup/Login",
    },
    setting: {
      mainTitle: "Profile",
      tabSetting: "Setting",
      tabUserInformation: "User Information",
      settingChild: {
        title: "Setting",
        changeEmailTitleBar: "Email",
        changeEmailTitle: "Change your email address here",
        changeEmailLabel: "Email",
        changeEmailPlaceholder:'Enter your new email.',
        digitalCodeLabel: "Digital Code",
        digitalCodeSend: "Send",
        changePhoneNumberTitleBar: "Phone Number",
        changePhoneNumberTitle: "Change your phone number here",
        changePhoneNumberLabel: "Phone Number",
        changePasswordTitleBar: "Change Password",
        changePasswordTitle: "Create new password",
        changePasswordTitleSubText: () => (
          <>
            Your new password must be different from <br /> the previous used
            password
          </>
        ),
        validationMatch: "Passwords match",
        validationNumber: "Has number",
        validationUppercase: "Has uppercase",
        validationLowercase: "Has lowercase",
        validationSpecial: "Has special character",
        previousPassword: "Previous Password",
        password: "Password",
        confirmPassword: "Confirm password",
        submit: "Submit",
        resetForm: "Reset Form",
        generatePassword: "Generate Password",
        passwordStrength: "Password Strength",
      },
      userInformation: {
        title: "User Information",
        submit: "Submit",
        personalInfo: {
          removeDialogMessage1:'Are you sure',
          removeDialogMessage2:'you want to delete your photo?',
          removeDialogButton: "remove",
          profileImage: "Profile Image",
          name: "I am",
          username: "Username",
          inviter: "Inviter",
          invitationCode: "Invitation Code",
          placeholderInviter: "Enter Your Invitation Code",
          placeholderInvitationCode: "Enter the invitation code if you have one",
          placeholderName: "Enter Your Name",
          placeholderUsername: "Enter Your Username",
          position: "Position",
          option1: "Car owner",
          option2: "Driver",
          option3: "Repairman",
          option4: "Seller",
          option5: "Other",
          dialogButton: "Send",
        },
        logout:"Logout"
      },
    },
    messages: {
      emailOrPhoneNumberEmpty: "The email or phone number field is empty.",
      emailEmpty: "The email field is empty.",
      phoneNumberEmpty: "The phone number field is empty.",
      phoneNumberMustBeValid: "The phone number must be a valid phone number.",
      emailMustBeValid: "The email must be a valid email address.",
      phoneNumberContainAnInvalidNumber:
        "The phone number field contains an invalid number.",
      emailOrPhoneNumberRequired: "email or phone number required",
      invalidGrant: "invalid_grant",
      usernameRequired: "The password field is required.",
      passwordRequired: "The password field is required.",
      successfullyLogout: "You have successfully logged out!",
      unauthenticated: "Unauthenticated.",
      verificationCodeSentToOldEmailForVerifyIt:
        "Verification code sent to old email for verify it.",
      verificationCodeSentToNewEmailForVerifyIt:
        "Verification code sent to new email for verify it.",
      emailChangedSuccessful: "email changed successfull.",
      anUnexpectedErrorOccured: "An unexpected error occured!",
      verificationCodeIsInvalid: "The verification_code is invalid.",
      theEmailHasAlreadyBeenTaken: "The email has already been taken.",
      yourInformationUpdatedSuccessful: "Your informations updated successful.",
      youShouldLogin: "you should login then change your information",
      usernameHasAlreadyBeenTaken: "The username has already been taken.",
      yourPasswordUpdatedSuccessful: "Your password updated successful.",
      previousPasswordRequired: "The previous_password is invalid.",
      verificationCodeSentToOldPhoneNumberForVerifyIt:
        "Verification code sent to old phone_number for verify it.",
      verificationCodeSentToNewPhoneNumberForVerifyIt:
        "Verification code sent to new phone_number for verify it.",
      phoneNumberChangedSuccessful: "phone_number changed successfull.",
      phoneNumberHasAlreadyBeenTaken:
        "The phone number has already been taken.",
      TheSelectedInviterIsInvalid: "The selected inviter is invalid.",
      networkError:
        "There is a problem, please try again after checking the internet.",
    },
    dir: "rtl",
    nearbymapsearch: {
      namayandegi: "representatives",
      zoo: "باغ وحش",
      airport: "فرودگاه",
      atm: "خود پرداز",
      bank: "بانک",
      bus: "ایستگاه اتوبوس",
      cafe: "کافه",
      doctor: "دکتر",
      gas_station: "جایگاه سوخت",
      hospital: "بیمارستان",
      hotel: "هتل",
      mosque: "مسجد",
      museum: "موزه",
      park: "پارک",
      parking: "پارکینگ",
      pharmacy: "داروخانه",
      police: "",
      rent_car: "",
      restaurant: "",
      store: "",
      superMarket: "سو",
      train: "قطار",
      travel: "مسافرت",
    },
    profile: {
      checklists: "Checklists",
      Finances: "Finances",
      username: "Ali Moeini",
      mobileNum: "09130173099",
      changeTruckBrand: "Change Truck Brand:",
      changBrand: "Change Brand",
      brands: ["Scania", "Volvo", "Renault", "Man", "Benz"],
      myActivities: "My Activities:",
      travels: "travels",
      purcheses: "Purcheses",
      posts: "Posts",
      favorites: "Favorites",
      comments: "Comments",
      eagleEyes: "Eagle Eye Members",
      messages: "Messages",
      setting: "Setting",
      myPayments: "My Payments",
      discounts: "Opportunities and Discounts",
      inviteYourFriends:
        "Invite your frineds to Eagle Eye members and get five points.",
    },
    sidebar: {
      version: "شماره نسخه 0.2",
      mainPage: "صفحه اصلی",
      map: "نقشه",
      diagnose: "عیب یابی",
      shop: "فروشگاه",
      account: "حساب کاربری",
      profile: "پروفایل",
      setting: "تنظیمات",
      travels: "سفرها",
      purcheses: "خریدها",
      balance: "موجودی خرید",
      payments: "پرداخت ها",
      favs: "علاقه مندی ها",
      forum: "انجمن ها",
      eagleEyeMembers: "چشم عقابی ها",
      posts: "پست ها",
      comments: "نظرات",
      messages: "پیام ها",
      discounts: "فرصت ها و تخفیف ها",
      aboutUs: "درباره ما",
      critisims: "انتقاد و پیشنهادات",
      support: "پشتیبانی و خدمات مادگستر",
      logout: "خروج",
    },
    problem: {
      problem: "عنوان مشکل",
      desc: "شرح مشکل",
      address: "آدرس متنی",
      send: "ارسال",
      sendLocation: "ارسال موقعیت مکانی به پشتیبانی",
      askForHelp: "درخواست امداد",
      location: "موقعیت مکانی",
      msgRecieved: "!درخواست امداد شما ارسال شد",
      yourTackingCode: (code) => `کد پیگیری شما: ${code}`,
      copyCode: "کپی کردن کد پیگیری",
      trackingCodeSaved: "کد پیگیری شما در کلیپ بورد کپی شد.",
      msgNotReceived: "Your message was not send",
      tryAgain: "لطفا دوباره امتحان کنید",
      ok: "OK",
    },
  },
  tr: {
    digitalCode: {
      enterCode: "Enter Digital Code",
      confirmationSent: (input) => <>Confirmation code sent to your {input}</>,
      email: "email",
      mobile: "mobile",
      code: "Digital code",
      login: "Login",
      resend1: "Resend the code",
      resend2: "until another",
      dialogTitle: "Temperory Code Has Been Sent!",
      dialogMessageEmail: "Please check your email",
      dialogMessageMobile: "Please check your mobile",
      dialogButtonText: "Ok",
    },
    signUpEmail: {
      notValidEmail: "The email must be a valid email address.",
      notValidMobile: "The phone number must be a valid phone number.",
      coupleButtonEmail: "E-Mail",
      coupleButtonMobile: "Phone Number",
      loginSignUp: "Login / SignUp",
      login: "Login",
      or: "Or",
      privacyPolicy:() => (
        <>
          <p style={{marginTop:'0'}}>
          Dear user, please carefully consider the following items for optimal use of Eagle Eye software services.
         Entering the Eagle Eye software means being aware of and accepting the terms and conditions as well as how to use the Eagle Eye services. Note that registering an order at any time means full acceptance of all terms and conditions of the Eagle Eye by the user. It should be noted that the terms and conditions set forth are an alternative to all previous agreements.
          </p>
          <p>
          General rules: <br/>
         Note all the principles and procedures of the eagle eye in accordance with the laws of the Islamic Republic of Iran, the law of electronic commerce and the law
         It protects the rights of the consumer and consequently the user is obliged to comply with the laws related to the user. If in the rules
         The contents, procedures and services of Eagle Eye will be changed in the future, will be published and updated on this page and you
         You agree that your continued use of the Program constitutes your acceptance of any such changes.
          </p>
          <p>
            
          Customer or user definition: <br/>
         A customer or user is a person who, with his / her user information entered in the registration form, registers an order or any
         Use eagle eye services.
         Also because Eagle Eye is an online retail application, according to e-commerce law any customer or consumer
         A person who buys goods or services for a purpose other than business or professional occupation.
          </p>
          <p>
          Definition of seller and seller responsibility: <br/>
         The seller is called Eagle Eye software that sells its products to the user.
         Responsibilities related to quality, price, content, conditions as well as after-sales service of the product are the responsibility of the seller.
         Eagle Eye after-sales service only accepts the customer's request to return the seller if there is a defect in the product.
         Not objected.
          </p>
          <p>
          Privacy and Policy: <br/>
        Eagle Eye emphasizes information that respects the privacy of users, to buy, to process and send information
        Like the address, a phone number is required
        It is reminded that the phones that the customer registers in his profile are only official and approved phones of the customer and all correspondences and
        The company's responses are made through them.
        Therefore, the inclusion of the address, mobile and landline contact numbers by the customer, confirms their authenticity and if
        If the above items are not entered correctly or completely, the eagle eye can ensure the accuracy and certainty of order registration from
        Customer, request additional information and more.
        Customers can enter the name, address and phone number of another person to receive the order Eagle Eye just to send the same
        The order will use this information.
          </p>
          <p>
          Order registration, processing and sending: <br/>
          - Working day means Saturday to Thursday of each week, with the exception of public holidays in Iran. <br/>
          - The working hours of this store are from 8 am to 6 pm. <br/>
        All orders placed during the day and night, if registered during store hours, will be processed on the same day, and orders placed during business hours will be processed.
        If not, they will be processed on the next working day. <br/> Eagle's eyes to their customers
        Invoice delivers all products to the recipient at the place of delivery.
        Products that do not have a fixed weight due to changes in the weight of the product, a new invoice is issued and the customer is notified before sending.
          </p>
          <p>
          How to pay the bill: <br/>
         All customer payments are made online at the time of delivery of the goods or through the payment gateway. In case of change in payment, the necessary notifications will be made.
          </p>
        </>
      ),
      privacyPolicyModalTitle:"Privacy Policy",
      closePrivacyPolicy:"I read the rules and accept",
    },
    enterPassword: {
      dialogTitle: "Your account has been found!",
      dialogMessage: "Please enter your password on the next page",
      dialogButton: "Ok",
      title: "Enter your account password",
      message: "Welcome back",
      label: "Password",
      login: "Login",
      forgetPass: "Forget your password ?",
    },
    social: {
      google: "Sign Up With Google",
      facebook: "Sign Up With Facebook",
    },
    desktopLoginContainer: {
      make: () => (
        <h2>
          Make A Driving<br />
          To Remember
        </h2>
      ),
      description1:
        "Due to the different field of work, I did not have the experience of using the Eagle Eye services, but each of the kids on our technical team, who provided a product from the Eagle Eye collection, were both very satisfied and the training was very practical for them. Congratulations to dear Hassan Yousefi for the valuable website he has created",
      description2:"Driving is not a job, it is love that you can fall in love with with the help of attractive and practical eagle eye trainings.",
      description3:"Eagle Eye is perhaps the only collection in Iran, which offers practical and up-to-date training and various and cost-effective products tailored to the needs of the domestic and global market! Your mouth is hot",
      name1: "Ali Moeini",
      name2: "Hamed Zamani",
      name3: "Mohammad Roshani",
      subName1: "A driver",
    },
    mobileLoginContainer: {
      crPass: "Digital Code",
      digiCod: "Enter Temporary Code",
      enPass: "Enter Password",
      signUp: "Signup/Login",
    },
    setting: {
      mainTitle: "Profile",
      tabSetting: "Setting",
      tabUserInformation: "User Information",
      settingChild: {
        title: "Setting",
        changeEmailTitleBar: "Email",
        changeEmailTitle: "Change your email address here",
        changeEmailLabel: "Email",
        changeEmailPlaceholder:'Enter your new email.',
        digitalCodeLabel: "Digital Code",
        digitalCodeSend: "Send",
        changePhoneNumberTitleBar: "Phone Number",
        changePhoneNumberTitle: "Change your phone number here",
        changePhoneNumberLabel: "Phone Number",
        changePasswordTitleBar: "Change Password",
        changePasswordTitle: "Create new password",
        changePasswordTitleSubText: () => (
          <>
            Your new password must be different from <br /> the previous used
            password
          </>
        ),
        validationMatch: "Passwords match",
        validationNumber: "Has number",
        validationUppercase: "Has uppercase",
        validationLowercase: "Has lowercase",
        validationSpecial: "Has special character",
        previousPassword: "Previous Password",
        password: "Password",
        confirmPassword: "Confirm password",
        submit: "Submit",
        resetForm: "Reset Form",
        generatePassword: "Generate Password",
        passwordStrength: "Password Strength",
      },
      userInformation: {
        title: "User Information",
        submit: "Submit",
        personalInfo: {
          removeDialogMessage1:'Are you sure',
          removeDialogMessage2:'you want to delete your photo?',
          removeDialogButton: "remove",
          profileImage: "Profile Image",
          name: "I am",
          username: "Username",
          inviter: "Inviter",
          invitationCode: "Invitation Code",
          placeholderInviter: "Enter Your Invitation Code",
          placeholderInvitationCode: "Enter the invitation code if you have one",
          placeholderName: "Enter Your Name",
          placeholderUsername: "Enter Your Username",
          position: "Position",
          option1: "Car owner",
          option2: "Driver",
          option3: "Repairman",
          option4: "Seller",
          option5: "Other",
          dialogButton: "Send",
          logout:"Logout"
        },
      },
    },
    messages: {
      emailOrPhoneNumberEmpty: "The email or phone number field is empty.",
      emailEmpty: "The email field is empty.",
      phoneNumberEmpty: "The phone number field is empty.",
      phoneNumberMustBeValid: "The phone number must be a valid phone number.",
      emailMustBeValid: "The email must be a valid email address.",
      phoneNumberContainAnInvalidNumber:
        "The phone number field contains an invalid number.",
      emailOrPhoneNumberRequired: "email or phone number required",
      invalidGrant: "invalid_grant",
      usernameRequired: "The password field is required.",
      passwordRequired: "The password field is required.",
      successfullyLogout: "You have successfully logged out!",
      unauthenticated: "Unauthenticated.",
      verificationCodeSentToOldEmailForVerifyIt:
        "Verification code sent to old email for verify it.",
      verificationCodeSentToNewEmailForVerifyIt:
        "Verification code sent to new email for verify it.",
      emailChangedSuccessful: "email changed successfull.",
      anUnexpectedErrorOccured: "An unexpected error occured!",
      verificationCodeIsInvalid: "The verification_code is invalid.",
      theEmailHasAlreadyBeenTaken: "The email has already been taken.",
      yourInformationUpdatedSuccessful: "Your informations updated successful.",
      youShouldLogin: "you should login then change your information",
      usernameHasAlreadyBeenTaken: "The username has already been taken.",
      yourPasswordUpdatedSuccessful: "Your password updated successful.",
      previousPasswordRequired: "The previous_password is invalid.",
      verificationCodeSentToOldPhoneNumberForVerifyIt:
        "Verification code sent to old phone_number for verify it.",
      verificationCodeSentToNewPhoneNumberForVerifyIt:
        "Verification code sent to new phone_number for verify it.",
      phoneNumberChangedSuccessful: "phone_number changed successfull.",
      phoneNumberHasAlreadyBeenTaken:
        "The phone number has already been taken.",
      TheSelectedInviterIsInvalid: "The selected inviter is invalid.",
      networkError:
        "There is a problem, please try again after checking the internet.",
    },
    dir: "ltr",
    nearbymapsearch: {
      namayandegi: "representatives",
      zoo: "باغ وحش",
      airport: "فرودگاه",
      atm: "خود پرداز",
      bank: "بانک",
      bus: "ایستگاه اتوبوس",
      cafe: "کافه",
      doctor: "دکتر",
      gas_station: "جایگاه سوخت",
      hospital: "بیمارستان",
      hotel: "هتل",
      mosque: "مسجد",
      museum: "موزه",
      park: "پارک",
      parking: "پارکینگ",
      pharmacy: "داروخانه",
      police: "",
      rent_car: "",
      restaurant: "",
      store: "",
      superMarket: "سو",
      train: "قطار",
      travel: "مسافرت",
    },
    profile: {
      checklists: "Checklists",
      Finances: "Finances",
      username: "Ali Moeini",
      mobileNum: "09130173099",
      changeTruckBrand: "Change Truck Brand:",
      changBrand: "Change Brand",
      brands: ["Scania", "Volvo", "Renault", "Man", "Benz"],
      myActivities: "My Activities:",
      travels: "travels",
      purcheses: "Purcheses",
      posts: "Posts",
      favorites: "Favorites",
      comments: "Comments",
      eagleEyes: "Eagle Eye Members",
      messages: "Messages",
      setting: "Setting",
      myPayments: "My Payments",
      discounts: "Opportunities and Discounts",
      inviteYourFriends:
        "Invite your frineds to Eagle Eye members and get five points.",
    },
    sidebar: {
      version: "Version 0.2",
      mainPage: "Main Page",
      map: "Map",
      diagnose: "Diagnose",
      shop: "Shop",
      account: "Account",
      profile: "Profile",
      setting: "Settings",
      travels: "Tarvels",
      purcheses: "Purcheses",
      balance: "Blance",
      payments: "Payments",
      favs: "Favorites",
      forum: "Forum",
      eagleEyeMembers: "EagleEye Members",
      posts: "Posts",
      comments: "Comments",
      messages: "Messages",
      discounts: "Discounts",
      aboutUs: "About Us",
      critisims: "Critisims",
      support: "Maadgostar Support",
      logout: "Logout",
    },
    problem: {
      problem: "problem",
      desc: "description",
      address: "address",
      send: "send",
      sendLocation: "Send location to support",
      askForHelp: "َAsk for Help",
      location: "Location",
      msgRecieved: "Your help request was recieved!",
      yourTackingCode: (code) => `your tracking code is ${code}`,
      copyCode: "copy tracking code",
      trackingCodeSaved: "Your tracking code was saved to the clip board",
      msgNotReceived: "Your message was not send",
      tryAgain: "Please try again later.",
      ok: "باشه",
    },
  },
};

export default lang;
