import React, {useEffect} from "react";

const ChangeInfoContext = React.createContext();
const ChangeInfoContextSetState = React.createContext();

function ChangeInfoProvider({ children }) {
  const [name , setName] = React.useState('');
  const [companyName , setCompanyName] = React.useState('');
  const [companyCategory , setCompanyCategory] = React.useState('');
  const [companyPhone , setCompanyPhone] = React.useState('');
  const [companyEmail , setCompanyEmail] = React.useState('');
  const [companyAbout , setCompanyAbout] = React.useState('');
  const [companyLocation, setCompanyLocation] = React.useState({
    lat: 0,
    lng: 0,
  });
  const [username , setUsername] = React.useState('');
  const [position, setPosition] = React.useState(1);
  const [dialog , setDialog] = React.useState(false);
  const [croppedImageLoading, setCroppedImageLoading] = React.useState(false);
  const [profile , setProfile] = React.useState(false);
  const [codeInputValue, setCodeInputValue] = React.useState('');
  const [invitationCode , setInvitationCode] = React.useState(null);
  const [inviter , setInviter] = React.useState(null);

  return (
    <ChangeInfoContext.Provider value={{
      name ,
      companyName,
      companyCategory,
      companyPhone,
      companyEmail,
      companyAbout,
      companyLocation,
      username , 
      dialog , 
      croppedImageLoading , 
      profile , 
      position , 
      codeInputValue,
      invitationCode,
      inviter}}>
      <ChangeInfoContextSetState.Provider 
        value = {{
          setName,
          setCompanyName,
          setCompanyCategory,
          setCompanyPhone,
          setCompanyEmail,
          setCompanyAbout,
          setCompanyLocation,
          setUsername,
          setDialog,
          setCroppedImageLoading,
          setProfile,
          setPosition,
          setCodeInputValue,
          setInvitationCode,
          setInviter
        }} >
        {children}
      </ChangeInfoContextSetState.Provider>
    </ChangeInfoContext.Provider>
  );
}

function ChangeInfoState() {
  const {
    name ,
    companyName,
    companyCategory,
    companyPhone,
    companyEmail,
    companyAbout,
    companyLocation,
    username ,
    dialog,
    croppedImageLoading,
    profile,
    position,
    codeInputValue,
    invitationCode,
    inviter
  } = React.useContext(ChangeInfoContext);

  if (
    name === undefined ||
    companyCategory === undefined ||
    companyPhone === undefined ||
    companyEmail === undefined ||
    companyName === undefined ||
    companyAbout === undefined ||
    companyLocation === undefined ||
    username === undefined ||
    dialog === undefined ||
    croppedImageLoading === undefined ||
    profile === undefined ||
    position === undefined ||
    codeInputValue === undefined ||
    invitationCode === undefined ||
    inviter === undefined
  ) {
    throw new Error("render <ChangeInfoProvider /> at top of the tree");
  }

  return {
    name,
    companyName,
    companyCategory,
    companyPhone,
    companyEmail,
    companyAbout,
    companyLocation,
    username ,
    dialog,
    croppedImageLoading,
    profile,
    position,
    codeInputValue,
    invitationCode,
    inviter
  };
}


// 
function ChangeInfoSetState() {
  const {
    setName,
    setCompanyName,
    setCompanyCategory,
    setCompanyPhone,
    setCompanyEmail,
    setCompanyAbout,
    setCompanyLocation,
    setUsername,
    setDialog,
    setCroppedImageLoading,
    setProfile,
    setPosition,
    setCodeInputValue,
    setInvitationCode,
    setInviter
  } = React.useContext(ChangeInfoContextSetState);

  if (
    setName === undefined ||
    setCompanyCategory === undefined ||
    setCompanyPhone === undefined ||
    setCompanyEmail === undefined ||
    setCompanyName === undefined ||
    setCompanyLocation === undefined ||
    setCompanyAbout === undefined ||
    setUsername === undefined ||
    setDialog === undefined ||
    setCroppedImageLoading === undefined ||
    setProfile === undefined  ||
    setPosition === undefined ||
    setCodeInputValue === undefined ||
    setInvitationCode === undefined ||
    setInviter === undefined
  ) {
    throw new Error("render <ChangeInfoProvider /> at top of the tree");
  }

  return {
    setName,
    setCompanyName,
    setCompanyCategory,
    setCompanyPhone,
    setCompanyEmail,
    setCompanyAbout,
    setCompanyLocation,
    setUsername,
    setDialog,
    setCroppedImageLoading,
    setProfile,
    setPosition,
    setCodeInputValue,
    setInvitationCode,
    setInviter
  };
}


// 
function NameChangeInfoSetState() {
    const { setName } = ChangeInfoSetState();

    const setNameChangeInfoMethod = (name) => {
        setName(name);
    };

    return { setNameChangeInfoMethod };
}

function ChangeCompanyNameHandler() {
  const { setCompanyName } = ChangeInfoSetState();

  const changeCompanyNameHandler = (name) => {
    setCompanyName(name);
  };

  return { changeCompanyNameHandler };
}

function ChangeCompanyCategoryHandler() {
  const { setCompanyCategory } = ChangeInfoSetState();

  const changeCompanyCategoryHandler = (category) => {
    setCompanyCategory(category);
  };

  return { changeCompanyCategoryHandler };
}

function ChangeCompanyPhoneHandler() {
  const { setCompanyPhone } = ChangeInfoSetState();

  const changeCompanyPhoneHandler = (phone) => {
    setCompanyPhone(phone);
  };

  return { changeCompanyPhoneHandler };
}

function ChangeCompanyLocationHandler() {
  const { setCompanyLocation } = ChangeInfoSetState();

  const changeCompanyLocationHandler = (location) => {
    setCompanyLocation(location);
  };

  return { changeCompanyLocationHandler };
}

function ChangeCompanyEmailHandler() {
  const { setCompanyEmail } = ChangeInfoSetState();

  const changeCompanyEmailHandler = (email) => {
    setCompanyEmail(email);
  };

  return { changeCompanyEmailHandler };
}
function ChangeCompanyAboutHandler() {
  const { setCompanyAbout } = ChangeInfoSetState();

  const changeCompanyAboutHandler = (email) => {
    setCompanyAbout(email);
  };

  return { changeCompanyAboutHandler };
}

function UsernameChangeInfoSetState() {
  const { setUsername } = ChangeInfoSetState();

  const setUsernameChangeInfoMethod = (username) => {
    setUsername(username);
  };

  return { setUsernameChangeInfoMethod };
}

function DialogSetState() {
  const { setDialog } = ChangeInfoSetState();

  const setDialogChangeInfo = (dialog) => {
    setDialog(dialog);
  };

  return { setDialogChangeInfo };
}

function CroppedImageLoadingSetState() {
  const { setCroppedImageLoading } = ChangeInfoSetState();

  const setCroppedImageLoadingChangeInfo = (img) => {
    setCroppedImageLoading(img);
  };

  return { setCroppedImageLoadingChangeInfo };
}

function ProfileSetState() {
  const { setProfile } = ChangeInfoSetState();

  const setProfileImage = (img) => {
    setProfile(img);
  };

  return { setProfileImage };
}

function PositionSetState() {
  const { setPosition } = ChangeInfoSetState();

  const setPositionImage = (value) => {
    setPosition(+value);
  };

  return { setPositionImage };
}

function SetCodeInputValueSetState() {
  const { setCodeInputValue } = ChangeInfoSetState();

  const setCodeInputValueSetState = (value) => {
    setCodeInputValue(value);
  };

  return { setCodeInputValueSetState };
}

function SetInvitationCodeSetState() {
  const { setInvitationCode } = ChangeInfoSetState();

  const setInvitationCodeSetState = (value) => {
    setInvitationCode(value);
  };

  return { setInvitationCodeSetState };
}

function SetInviterSetState() {
  const { setInviter } = ChangeInfoSetState();

  const setInviterSetState = (value) => {
    setInviter(value);
  };

  return { setInviterSetState };
}


export { 
  ChangeInfoState,
  ChangeInfoSetState,
  NameChangeInfoSetState,
  ChangeCompanyCategoryHandler,
  ChangeCompanyPhoneHandler,
  ChangeCompanyAboutHandler,
  ChangeCompanyEmailHandler,
  ChangeCompanyLocationHandler,
  ChangeCompanyNameHandler,
  UsernameChangeInfoSetState,
  DialogSetState,
  CroppedImageLoadingSetState,
  ProfileSetState,
  PositionSetState,
  SetCodeInputValueSetState,
  SetInvitationCodeSetState,
  SetInviterSetState
};

export default ChangeInfoProvider;
