import React, {useEffect, useMemo, useState,Suspense} from "react";
import {CgShoppingBag} from "react-icons/cg";
import {Menu, MenuItem,} from "react-pro-sidebar";
import {NavLink} from "react-router-dom";
import {useLanguage} from "../../Provider/langProvider/LangProvider";
import Aside from "./ProSideBar/Aside";
import Burger from "Assets/burger.png";
import styles from "./Dashboard.module.css";
import DashbordHeader from "./DashbordHeader";
import {MdOutlineFreeBreakfast} from 'react-icons/md'
import {HiOutlineQuestionMarkCircle} from 'react-icons/hi'
import {BsCollection} from 'react-icons/bs'
import {useTranslation} from "react-i18next";
import Divider from '@mui/material/Divider';
import {useLocation} from "react-router";
import LoadingImg from "../LoadingImg/LoadingImg";
import {useConfig} from "../../Provider/ConfigProvider/ConfigProvider";

const Dashboard = ({children, title}) => {

  const location = useLocation()


  useEffect(() => {
    document.body.setAttribute("data-show-crisp", "false");
  }, []);
  const {
    dir,
  } = useLanguage();

  const {t: availabilityT} = useTranslation('availability');
  const {t: commonT} = useTranslation('common');


  const [toggled, setToggled] = useState(false);
  const [collapsed, setCollapsed] = React.useState(false);

  const syncApp = useConfig()

  const handleToggleSidebar = (boolian) => {
    setToggled(boolian);
  };
  const PrimalMenues = [
    {
      id: 1,
      component:
        <NavLink className={styles.navLink} to="/">
          <MenuItem active={location.pathname==='/'} icon={<CgShoppingBag/>}>
            {commonT('dashboardLabel')}
          </MenuItem>
        </NavLink>
    },
    {
      id: 2,
      component: <Divider style={{background: "#ffffff33"}}/>
    },
    {
      id: 1,
      component:
        <NavLink className={`${styles.navLink} ${styles.availabilityBtn}`} to="/availability">
          <MenuItem active={location.pathname==='/availability'}   icon={<MdOutlineFreeBreakfast/>}>
          {/*  <span className={`${styles.errorIconContainer} ${dir === 'rtl' && styles.rtl}`}><Tooltip*/}
            {/*    title={availabilityT('unApprovalLabel')}>*/}
          {/*  <IconButton>*/}
          {/*    <ErrorOutlineIcon/>*/}
          {/*  </IconButton>*/}
          {/*</Tooltip></span>*/}
            {commonT('availabilityLabel')}
          </MenuItem>
        </NavLink>
    },
    {
      id: 2,
      component: <Divider style={{background: "#ffffff33"}}/>
    },
    ...((syncApp?.questions?.supplier||syncApp?.questions)?[{
      id: 1,
      component:
          <NavLink className={styles.navLink} to="/faqs">
            <MenuItem  active={location.pathname==='/faqs'}  icon={<HiOutlineQuestionMarkCircle/>}>
              {commonT('faqsLabel')}
            </MenuItem>
          </NavLink>
    }]:[]),
    {
      id: 2,
      component: <Divider style={{background: "#ffffff33"}}/>
    },
    {
      id: 1,
      component:
        <NavLink className={styles.navLink} to="/collections">
          <MenuItem active={location.pathname==='/collections'} icon={<BsCollection/>}>
            {commonT('yourCollectionLabel')}
          </MenuItem>
        </NavLink>
    },
  ]
  const Menues = useMemo(() => PrimalMenues.map(item => item.component),[syncApp])


  return (
    <div
      className={styles.container + " " + dir}
      style={{direction: dir}}
    >

      <Aside
        rtl={dir === "rtl"}
        toggled={toggled}
        breakPoint="lg"
        handleToggleSidebar={handleToggleSidebar}
        hide={false}
        permissions
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      >
        <Menu iconShape="circle">
          {Menues}
        </Menu>
      </Aside>


      <div className={styles.main}>
        <DashbordHeader title={title}/>
        <div className={`${styles.btn_toggle} ${dir === 'rtl' && styles.rtl}`}
             onClick={() => handleToggleSidebar(true)}>
          <img
            src={Burger}
            alt=""
            width="100%"
            height="100%"
            className={styles.burger}
          />
        </div>

        <div
          style={{
            flexGrow: "1",
            height: "100%",
            overflowY: "auto",
            width: "100%",
            position: 'relative'
          }}
        >
          <Suspense  fallback={<LoadingImg/>}>
            {children}
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
