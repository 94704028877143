import {createContext, useContext, useEffect} from "react";
import useClosureFetch from "../../hooks/useClosureFetch";
import useDefaultFetch from "../../hooks/useDefaultFetch";
import {useLang} from "../langProvider/LangProvider";
import {LogoutActions} from "../LoginProvider";
import {useHistory} from "react-router-dom";
import {DialogSetState} from "../ChangeInfo/ChangeInfo";
// import VersionUpdateModal from "./VersionUpdateModal";

const ConfigContext = createContext();
const ConfigContextSetState = createContext();

function update(data, update) {
  localStorage.Version = JSON.stringify({
    Update: process.env.REACT_APP_VERSION,
    ForceUpdate: data?.version.code,
    changes: data?.version.changes
  })
  try {
    caches.keys().then(function (names) {
      for (let name of names) caches.delete(name).catch(null);
      window.onbeforeunload = null
      // noinspection JSUnresolvedFunction
      window.location.reload(true)
    });
  } catch (e) {
    console.log(e)
  }
  update()
}

function ConfigProvider({ children }) {
  const fetch = useClosureFetch()
  const lang = useLang();
  const { Logout } = LogoutActions()
  const history = useHistory()
  const { data: { data }, setState, loading } = useDefaultFetch('suppliers/sync_app', {
    data: {
      systems: [],
      sliders: [],
      version: {},
      max_tracker_interval:300000,
      min_tracker_distance:50,
      firebase_tokens:[],
      user:{
        id:1,
        name:'Loading',
        position:1,
        username:'username',
        email:null,
        phone_number:null,
        profile:{
          location:null
        },
        has_password:false,
        invitation_code:null,
        inviter:null,
        unread_notices_count:'',
        parent:null,
        token:{
          domains: [],
          expiration_time: null,
          id: null,
          max_products: null,
          max_users: null,
          price: null,
          store_id: null,
          token: null,
        },
      }
    }
  }, '')

  const {setDialogChangeInfo} = DialogSetState()
  useEffect(() => {
    fetchSyncApp()
  }, [lang])

  const fetchSyncApp=async()=>{
    try {
      const syncApp = await fetch('suppliers/sync_app', { method: 'post' })
      console.log(syncApp)
      console.log('syncApp')

      if(syncApp?.body?.data?.user){
        setState((prev) => {
          return { ...prev, data: syncApp.body }
        })
        localStorage.setItem('user',JSON.stringify(syncApp?.body?.data?.user))
        if(!syncApp?.body?.data?.user?.supplier_info||!syncApp?.body?.data?.user?.supplier_info?.company_name||!syncApp?.body?.data?.user?.supplier_info?.category_id){
          setDialogChangeInfo(true)
        }
      }
      else{
        Logout()
        history.push('/login')
      }
    } catch (e) {

    }
  }

  return (
    <ConfigContext.Provider value={{ loading, ...data }}>
      <ConfigContextSetState.Provider value={setState}>
        {children}
        {/*<VersionUpdateModal version={data?.version} />*/}
      </ConfigContextSetState.Provider>
    </ConfigContext.Provider>
  );
}

function FetchSyncApp(){
  const fetche = useClosureFetch()
  const setstate = UseSetState();
  const {setDialogChangeInfo} = DialogSetState()
  const fetchSyncApp=async()=>{
    try {
      const syncApp = await fetche('suppliers/sync_app', { method: 'post' })

      setstate((prev) => {
        return { ...prev, data: syncApp.body }
      })
      if(!syncApp?.body?.data?.user?.supplier_info||!syncApp?.body?.data?.user?.supplier_info?.company_name||!syncApp?.body?.data?.user?.supplier_info?.category_id){
        setDialogChangeInfo(true)
      }
      localStorage.setItem('user',JSON.stringify(syncApp?.body?.data?.user))
    } catch (e) {

    }
  }

  return {fetchSyncApp}
}

function useConfig() {
  const Config = useContext(ConfigContext);
  if (Config === undefined) {
    throw new Error("render <ConfigProvider /> at top of the tree");
  }

  return Config;
}

function UseSetState() {
  const ConfigSetState = useContext(ConfigContextSetState);
  if (ConfigSetState === undefined) {
    throw new Error("render <ConfigProvider /> at top of the tree");
  }

  return ConfigSetState
}

function SetStateByKey() {
  const setstate = UseSetState();
  return (key, state) => {
    setstate(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        data: {
          ...prevState.data.data,
          [key]: state
        }
      }
    }))
  };
}

export { useConfig, SetStateByKey, UseSetState ,FetchSyncApp };
export default ConfigProvider;
