import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AppContext from "./AppContext";
import './i18n';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://9ee56ca816f34f578c73e216e3d156fb@o1126993.ingest.sentry.io/4504740672307200",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
  });
}
const AllowedProgects = ['wedding','explorer']
if (!['wedding','explorer'].includes(process.env.REACT_APP_PROGECT)) throw new Error("build failed please specify a valid progect")

ReactDOM.render(
  <React.StrictMode>
    <AppContext/>
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals();
