import React from "react";

const ChangePasswordContext = React.createContext();
const ChangePasswordContextSetState = React.createContext();

const retrieveUserInfo = localStorage.getItem("UserInfo");

function ChangePasswordProvider({ children }) {
  const [password , setPassword] = React.useState('')
  const [passwordConfirmation , setPasswordConfirmation] = React.useState('')
  const [previousPassword , setPreviousPassword] = React.useState('')
  const [prevPassIsSet, setPrevPassIsSet] = React.useState(() => {
        if (retrieveUserInfo && retrieveUserInfo.length > 2) return JSON.parse(retrieveUserInfo).has_password;
        else {
            return false;
        }
    });

  return (
    <ChangePasswordContext.Provider value={{
        password,
        passwordConfirmation,
        previousPassword,
        prevPassIsSet
    }}>
      <ChangePasswordContextSetState.Provider 
        value = {{
            setPassword,
            setPasswordConfirmation,
            setPreviousPassword,
            setPrevPassIsSet
        }} 
      >
        {children}
      </ChangePasswordContextSetState.Provider>
    </ChangePasswordContext.Provider>
  );
}

function ChangePasswordState() {
    const {
        password ,
        passwordConfirmation,
        previousPassword,
        prevPassIsSet
    } = React.useContext(ChangePasswordContext);

    if (
        password === undefined ||
        passwordConfirmation === undefined ||
        previousPassword === undefined ||
        prevPassIsSet === undefined 
    ) {
        throw new Error("render <ChangePasswordProvider /> at top of the tree");
    }

    return {
        password,
        passwordConfirmation,
        previousPassword,
        prevPassIsSet 
    };
}

function ChangePasswordSetState() {
    const {
        setPassword,
        setPasswordConfirmation,
        setPreviousPassword,
        setPrevPassIsSet
    } = React.useContext(ChangePasswordContextSetState);

    if (
        setPassword === undefined   ||
        setPasswordConfirmation === undefined ||
        setPreviousPassword === undefined   ||
        setPrevPassIsSet === undefined 
    ) {
        throw new Error("render <ChangePasswordProvider /> at top of the tree");
    }

    const setChangePasswordMethod = (pass) => {
        setPassword(pass);
    };

    const setChangePasswordConfirmationMethod = (pass) => {
        setPasswordConfirmation(pass);
    };

    const setChangePreviousPasswordMethod = (pass) => {
        setPreviousPassword(pass);
    };
    
    const setChangePrevPassIsSetMethod = (pass) => {
        setPrevPassIsSet(pass)
    };

    return {
        setChangePasswordMethod,
        setChangePasswordConfirmationMethod,
        setChangePreviousPasswordMethod,
        setChangePrevPassIsSetMethod
    };
}





export { 
    ChangePasswordState,
    ChangePasswordSetState
};

export default ChangePasswordProvider;
