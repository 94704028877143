import React from "react";

const ChangeEmailContext = React.createContext();
const ChangeEmailContextSetState = React.createContext();

function ChangeEmailProvider({ children }) {
  const [email , setEmail] = React.useState('')
  const [verificationCode , setVerificationCode] = React.useState('')
  const [showCodeBox , setShowCodeBox] = React.useState(false)


  return (
    <ChangeEmailContext.Provider value={{email , verificationCode , showCodeBox}}>
      <ChangeEmailContextSetState.Provider 
        value = {{setEmail, setVerificationCode , setShowCodeBox}} 
      >
        {children}
      </ChangeEmailContextSetState.Provider>
    </ChangeEmailContext.Provider>
  );
}

function ChangeEmailState() {
  const {email} = React.useContext(ChangeEmailContext);

  if (email === undefined) {
    throw new Error("render <ChangeEmailProvider /> at top of the tree");
  }

  return {email};
}

function ChangeEmailSetState() {
  const {setEmail} = React.useContext(ChangeEmailContextSetState);

  if (setEmail === undefined) {
    throw new Error("render <ChangeEmailProvider /> at top of the tree");
  }

  const setChangeEmailMethod = (email) => {
      setEmail(email);
  };

  return {setChangeEmailMethod};
}


function ChangeVerificationCodeState() {
  const {verificationCode} = React.useContext(ChangeEmailContext);

  if (verificationCode === undefined) {
    throw new Error("render <ChangeEmailProvider /> at top of the tree");
  }

  return {verificationCode};
}
// 



function ChangeVerificationCodeSetState() {
    const { setVerificationCode } = React.useContext(ChangeEmailContextSetState);

    if (setVerificationCode === undefined) {
      throw new Error("render <ChangeEmailProvider /> at top of the tree");
    }
    
    const setVerificationCodeMethod = (ver) => {
      setVerificationCode(ver);
    };

    return { setVerificationCodeMethod };
}


// 
function ChangeShowCodeBoxState() {
  const {showCodeBox} = React.useContext(ChangeEmailContext);

  if (showCodeBox === undefined) {
    throw new Error("render <ChangeEmailProvider /> at top of the tree");
  }

  return {showCodeBox};
}
// 



function ChangeShowCodeBoxSetState() {
    const { setShowCodeBox } = React.useContext(ChangeEmailContextSetState);

    if (setShowCodeBox === undefined) {
      throw new Error("render <ChangeEmailProvider /> at top of the tree");
    }
    
    const setChangeShowCodeBoxMethod = (codeBox) => {
      setShowCodeBox(codeBox);
    };

    return { setChangeShowCodeBoxMethod };
}



export { 
  ChangeEmailState,
  ChangeEmailSetState,
  ChangeVerificationCodeState,
  ChangeVerificationCodeSetState,
  ChangeShowCodeBoxState,
  ChangeShowCodeBoxSetState
};

export default ChangeEmailProvider;
