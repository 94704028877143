import React, {useEffect} from "react";
import {checkCookies, getCookie, setCookies} from 'cookies-next'

const LoginContext = React.createContext();
const LoginContextSetState = React.createContext();

Date.prototype.addSeconds = function(m) {
  this.setTime(this.getTime() + (m*1000));
  console.log("addMinutes",m,this)
  return this;
}
// token in storage
const saveLogin = (login,expires= 30*24*60*60) => {
  setCookies("Login", login, { expires: new Date().addSeconds(expires) })
};
const existLogin = checkCookies('Login');

function LoginProvider({ children , isLogin=!!existLogin }) {
  const [login, setLogin] = React.useState(isLogin);
  useEffect(() => {
    if(getCookie("Login")){
      setLogin(+getCookie("Login"))
    }
  })
  return (
    <LoginContext.Provider value={login}>
      <LoginContextSetState.Provider value={setLogin}>
        {children}
      </LoginContextSetState.Provider>
    </LoginContext.Provider>
  );
}

function LoginState() {
  const loginState = React.useContext(LoginContext);
  const setLogin = React.useContext(LoginContextSetState);

  useEffect(() => {
    setLogin(+loginState);
  }, [loginState]);
  if (loginState===undefined) {
    throw new Error("render <LoginProvider /> at top of the tree");
  }

  return +loginState;
}

function LoginSetState() {
  const setLogin = React.useContext(LoginContextSetState);

  if (setLogin === undefined) {
    throw new Error("render <LoginProvider /> at top of the tree");
  }

  return setLogin;
}

function LoginActions() {
  const setLogin = LoginSetState();

  const Login = (expires) => {
    setLogin((prevLogin) => (prevLogin = 1));
    saveLogin(1,expires);
  };

  return { Login };
}

function LogoutActions() {
  const setLogin = LoginSetState();

  const Logout = () => {
    try{
      throw new Error
    }catch (e){
      console.error('LogoutActions why',e)
    }
    setLogin((prevLogin) => (prevLogin = 0));
    saveLogin(0);
  };

  return { Logout };
}

export { LoginState, LoginSetState, LoginActions, LogoutActions };
export default LoginProvider;
