import * as React from 'react';
import {ReactComponent as ExplorerLogo} from "../../Assets/explorerOfOman.svg";
import styles from "components/Dashboard/ProSideBar/Aside.module.css";

interface Props {

}

const Logo = (props: Props) => {
  if (process.env.REACT_APP_PROGECT ==="wedding") return <div style={{display:'flex',gap:"1rem",alignItems:'center'}}><img alt="" width={50} height={50} src={"/WEDDFavicon.png"}/><span>Wedd State</span></div>
  if (process.env.REACT_APP_PROGECT ==="explorer") return <ExplorerLogo  className={styles.image} />
  return null
};

export default Logo;
