import React from 'react'
import styles from './LanguagePicker.module.css'
import {setLang, useLang} from "../../../Provider/langProvider/LangProvider";
// images
import flagUsa from "../../../Assets/united-states.svg";
import flagIran from "../../../Assets/Flag_of_Iran.png";
import flagArabic from "../../../Assets/Flag-Oman.jpg";
import {useTranslation} from 'react-i18next'

function LanguagePicker() {
  const [active, setAtive] = React.useState(false);
  const Lang = useLang();
  const setLanguage = setLang();
  const {t, i18n} = useTranslation('common')
  const langsLabels = t("languages", {returnObjects: true})
  const changeLanguageHandler = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang)
  };

  // Select Box
  let languageSelect = null;
  if (Lang === "en") {
    languageSelect = (
      <React.Fragment>
        <img src={flagUsa} alt=""/>
        {/* <span>English</span> */}
      </React.Fragment>
    );
  } else if (Lang === "fa") {
    languageSelect = (
      <React.Fragment>
        <img src={flagIran} alt=""/>
        {/* <span>Persian</span> */}
      </React.Fragment>
    );
  } else if (Lang === "ar") {
    languageSelect = (
      <React.Fragment>
        <img src={flagArabic} alt=""/>
        {/* <span>Arabic</span> */}
      </React.Fragment>
    );
  }
  //

  return (
    <div className={styles.language}>
      <div onClick={() => setAtive((prevActive) => !prevActive)}>
        {languageSelect}
        {/* <AngleBottom
            style={ active ? { transform: "rotate(180deg)", transition: "transform .1s" } : null }
          /> */}
      </div>
      <div
        className={`${styles.SelectBackdrop} ${active ? styles.active : null}`}
        onClick={() => setAtive(false)}
      />
      <ul
        className={`${styles.selectLanguage} ${active ? styles.active : null}`}
      >
        <li
          onClick={() => {
            changeLanguageHandler("en");
            setAtive(false);
          }}
        >
          <img src={flagUsa} alt=""/>
          <span>{langsLabels.english}</span>
        </li>

        <li
          onClick={() => {
            changeLanguageHandler("fa");
            setAtive(false);
          }}
        >
          <img src={flagIran} alt=""/>
          <span>{langsLabels.persian}</span>
        </li>

        <li
          onClick={() => {
            changeLanguageHandler("ar");
            setAtive(false);
          }}
        >
          <img src={flagArabic} alt=""/>
          <span>{langsLabels.arabic}</span>
        </li>
      </ul>
    </div>
  )
}

export default LanguagePicker
