// eslint-disable import/first
import { Capacitor } from "@capacitor/core";
import locationService from "./LocationService";
import {toast} from 'react-toastify'
import { NativeSettings } from 'capacitor-native-settings';
import {Geolocation} from '@capacitor/geolocation'
import MarkerClusterer from '@googlemaps/markerclustererplus';
let map_maker = {
  mode: "driving",
  transitmode: undefined,
  map: undefined,
  marker: undefined,
  loc_found_infowindow: undefined,
  loc_found_marker: undefined,
  curent_position: undefined,
  polyline: undefined,
  // user selected map position
  usmp: undefined,
  markerClusterer: {},
  nearby_places_marker: {},
  nearby_center: undefined,
  routing_button: document.createElement("button"),
  directionsDisplay: undefined,
  directionsService: undefined,
  end_of_rout: undefined,
  sessiontoken: undefined,
  mark_curent_location: async function () {
    return this.curent_location(() => {
      if (this.loc_found_marker) {
        this.loc_found_marker.setMap(null);
      }
      this.loc_found_marker = new window.google.maps.Marker({
        position: this.curent_position,
        map: this.map,
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE,
          fillColor: "#4d79ff",
          fillOpacity: 1,
          scale: 10,
          strokeColor: "#ccccff",
          strokeopacity: 0.02,
          strokeWeight: 6,
        },
      });
      // loc_found_marker.setContent("Location found.");
      // loc_found_marker.setIcon()
      this.map.setCenter(this.curent_position);
      this.map.setZoom(14);
      this.geocoder(this.loc_found_marker);
    });
  },
  geocoder: function (markerer) {
    const geocoderFetch = markerer.addListener("click", () => {
      window.google.maps.event.removeListener(geocoderFetch);
      let infoWindowContent = undefined;
      var formdataGeocode = new FormData();
      formdataGeocode.append(
        "Request",
        "https://maps.googleapis.com/maps/api/geocode/json?key=&latlng=" +
        markerer.position.lat() +
        "," +
        markerer.position.lng()
      );
      formdataGeocode.append("putKey", "a");
      fetch(process.env.REACT_APP_BASE_URL+"/api/google_map", {
        body: formdataGeocode,
        method: "POST",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "ZERO_RESULTS") {
            infoWindowContent = "google map position not recognized";
          } else {
            infoWindowContent = data.results[0].formatted_address;
          }
        })
        .then(() => {
          markerer.addListener("click", () => {
            if (infoWindow.getMap()) {
              infoWindow.close();
              this.routing_button.style.display = "none";
            } else {
              this.end_of_rout = markerer;
              infoWindow.open(this.map, markerer);
              this.routing_button.style.display = "flex";
            }
          });
          let infoWindow = new window.google.maps.InfoWindow({
            position: markerer.position,
            content: infoWindowContent,
          });
          infoWindow.open(this.map, markerer);
        })
        .catch((Error) => console.log(Error));
    });
  },
  curent_location: async function (_callback = () => {}) {
    let self = this;
    const hasPermission = await locationService.checkGPSPermission();
    // if(hasPermission){
    //   navigatorsss()
    // }else{
    //
    // }
    const postGPSPermission = async (canUseGPS) => {
      if (canUseGPS) {
        return navigatorsss();
      }
      else {
        toast.error('Please turn on GPS to get location')
      }
    }
    if (hasPermission) {
      if (Capacitor.isNativePlatform()) {
        const canUseGPS = await locationService.askToTurnOnGPS();
        return await postGPSPermission(canUseGPS);
      }
      else {
        return await postGPSPermission(true);
      }
    } else {
      console.log('14');
      const permission = await locationService.requestGPSPermission();
      if (permission === 'CAN_REQUEST' || permission === 'GOT_PERMISSION') {
        if (Capacitor.isNativePlatform()) {
          const canUseGPS = await locationService.askToTurnOnGPS();
          return await postGPSPermission(canUseGPS);
        }
        else {
          return await postGPSPermission(true);
        }
      }
      else {
        toast(<div>User denied location permission <span
          style={{textDecoration:'underline',fontSize:'1.1em'}}
          onClick={()=>{
            NativeSettings.openAndroid({
              option: 'location',
            });}}>to allow Access click Here</span></div>,{
          type:'error',autoClose:false,closeOnClick:false,draggable:false
        })
      }
    }

    async function navigatorsss() {
      const position = await Geolocation.getCurrentPosition();
      self.curent_position = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      _callback();
      return position

    }
  },
  handleLocationError: function (browserHasGeolocation) {
    if (this?.map === undefined) {
      console.log(
        browserHasGeolocation
          ? "Error: The Geolocation service failed."
          : "Error: Your browser doesn't support geolocation."
      );
    } else {
      if (window?.google?.maps) {
        if (!this?.loc_found_infowindow) {
          this.loc_found_infowindow = new window.google.maps.InfoWindow({
            position: this?.map?.getCenter(),
            content: browserHasGeolocation
              ? "Error: The Geolocation service failed."
              : "Error: Your browser doesn't support geolocation.",
          });
        }
      }
      this?.loc_found_infowindow?.setPosition(this?.map?.getCenter());
      this?.loc_found_infowindow?.setContent(
        browserHasGeolocation
          ? "Error: The Geolocation service failed."
          : "Error: Your browser doesn't support geolocation."
      );
      this?.loc_found_infowindow?.open(this?.map);
      setTimeout(() => {
        this?.loc_found_infowindow?.close();
      }, 3000);
    }
  },
  search_map: function (input, div) {
    if (!this.sessiontoken) {
      fetch("https://www.uuidtools.com/api/generate/v4")
        .then((data) => data.json())
        .then((res) => (this.sessiontoken = res[0]));
    }
    var formdatasearch = new FormData();
    formdatasearch.append(
      "Request",
      "https://maps.googleapis.com/maps/api/place/autocomplete/json?key=&input=" +
        input.value +
        "&components=country:om&types=address&sessiontoken=" +
        this.sessiontoken
    );
    formdatasearch.append("putKey", "a");
    fetch(process.env.REACT_APP_BASE_URL+"/api/google_map", {
      body: formdatasearch,
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(div);
        div.replaceChildren("");
        data.predictions.forEach((place) => {
          let search_li = document.createElement("li");
          search_li.append(document.createTextNode(place.description));
          search_li.addEventListener("click", () => {
            input.value = search_li.textContent;
            if (this.curent_position) {
              fetch(
                "https://maps.googleapis.com/maps/api/place/details/json?place_id=" +
                  place.place_id +
                  "&fields=name,geometry&key=&sessiontoken=" +
                  this.sessiontoken
              )
                .then((response) => response.json())
                .then((data) => {
                  this.sessiontoken = undefined;
                  this.map.setCenter(data.result.geometry.location);
                  let poi_marker = new window.google.maps.Marker({
                    position: data.result.geometry.location,
                    map: this.map,
                  });
                  this.geocoder(poi_marker);
                  return poi_marker;
                })
                .catch((Error) => console.log(Error));
            } else {
              this.curent_location(() => {
                fetch(
                  "https://maps.googleapis.com/maps/api/place/details/json?place_id=" +
                    place.place_id +
                    "&fields=name,geometry&key=&sessiontoken=" +
                    this.sessiontoken
                )
                  .then((response) => response.json())
                  .then((data) => {
                    this.sessiontoken = undefined;
                    this.map.setCenter(data.result.geometry.location);
                    let poi_marker = new window.google.maps.Marker({
                      position: data.result.geometry.location,
                      map: this.map,
                    });
                    this.geocoder(poi_marker);
                    return poi_marker;
                  })
                  .catch((Error) => console.log(Error));
              });
            }
            div.replaceChildren("");
          });
          div.appendChild(search_li);
        });
      })
      .catch((Error) => console.log(Error));
  },
  nearby_search: function (
    type,
    radius = 1000,
    keyword = "",
    imagePath = "/cluster/m",
    ICON = ""
  ) {
    if (this.nearby_places_marker[type] !== undefined) {
      return;
    }
    // if current place was found somehow
    const search = () => {
      if (this.nearby_places_marker[type]?.length) {
        this.markerClusterer[type].clearMarkers();
      }

      if (this.map.getCenter() !== this.curent_position) {
        this.map.setCenter(this.curent_position);
      }
      var formdatanearby = new FormData();
      formdatanearby.append(
        "Request",
        "https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=" +
          this.curent_position.lat +
          "," +
          this.curent_position.lng +
          "&radius=" +
          radius +
          "&type=" +
          type +
          "&keyword=" +
          "" +
          "&key="
      );
      formdatanearby.append("putKey", "a");
      fetch(process.env.REACT_APP_BASE_URL+"/api/google_map", {
        body: formdatanearby,
        method: "POST",
      })
        .then((response) => response.json())
        .then((data) => {
          // if we have places around
          if (data.results.length) {
            this.nearby_places_marker[type] = data.results.map(
              (placesmarker) => {
                if (!ICON) {
                  ICON = {
                    url: placesmarker.icon,
                    scaledSize: new window.google.maps.Size(35, 35),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(0, 0),
                  };
                }
                let nearby_marker = new window.google.maps.Marker({
                  position: placesmarker.geometry.location,
                  map: this.map,
                  icon: ICON,
                  getDraggable: () => false,
                });
                this.geocoder(nearby_marker);
                return nearby_marker;
              }
            );
            if (this.markerClusterer[type] === undefined) {
              this.markerClusterer[type] = new MarkerClusterer(
                this.map,
                this.nearby_places_marker[type],
                {
                  imagePath: imagePath,
                  textColor: "white",
                  ignoreHidden: true,
                }
              );
            } else {
              this.markerClusterer[type].addMarkers(
                this.nearby_places_marker[type]
              );
            }
            // if we dont have places around
          } else {
            let self_remove_info_window = new window.google.maps.InfoWindow({
              position: this.map.getCenter(),
              content:
                "no " + type + " found near you in " + radius + " meteres",
            });
            self_remove_info_window.open(this.map);
            setTimeout(() => {
              self_remove_info_window.close();
            }, 2000);
          }
        })
        .catch((Error) => console.log(Error));
    };
    if (this.curent_position) {
      search();
    } else {
      this.curent_location(search);
    }
  },
  toggleClusters: function (type) {
    if (this.nearby_places_marker[type] === undefined) {
      this.nearby_search(type);
      return;
    }
    if (this.markerClusterer[type]?.clusters_.length) {
      for (var it in this.nearby_places_marker[type]) {
        this.nearby_places_marker[type][it].setVisible(false);
      }
      this.markerClusterer[type].repaint();
    } else {
      for ( it in this.nearby_places_marker[type]) {
        this.nearby_places_marker[type][it].setVisible(true);
      }
      this.markerClusterer[type].repaint();
    }
  },
  routing: function (
    end = "",
    TransitOptions = this.transitmode,
    start = "",
    DrivingOptions = { departureTime: new Date() },
    mode = this.mode,
    provideRouteAlternatives = false,
    avoidFerries = false,
    avoidHighways = false,
    avoidTolls = false,
    region = ""
  ) {
    // if(typeof(this.polyline) !== "undefined"){this.polyline.setMap(null)}
    this.directionsDisplay.setMap(null);
    if (!start) {
      if (this.curent_position) {
        start = this.curent_position;
      } else {
        this.curent_location(() => {
          start = this.curent_position;
        });
      }
      if (!end) {
        if (this.end_of_rout) {
          end = {
            lat: this.end_of_rout.position.lat(),
            lng: this.end_of_rout.position.lng(),
          };
          this.end_of_rout.setMap(null);
        } else {
          console.error("wrong rout request");
          return;
        }
      }
      // var bounds = new window.google.maps.LatLngBounds();
      this.directionsService = new window.google.maps.DirectionsService();
      this.directionsDisplay = new window.google.maps.DirectionsRenderer({
        map: this.map,
        preserveViewport: true,
      });
      this.directionsService.route(
        {
          origin: start,
          destination: end,
          travelMode: window.google.maps.TravelMode[mode.toUpperCase()],
          transitOptions: TransitOptions,
          drivingOptions: DrivingOptions,
          unitSystem: window.google.maps.UnitSystem.METRIC,
          provideRouteAlternatives: provideRouteAlternatives,
          avoidFerries: avoidFerries,
          avoidHighways: avoidHighways,
          avoidTolls: avoidTolls,
          region: region,
        },
        (response, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            console.log(response);
            this.directionsDisplay.setMap(null);
            // this.directionsDisplay.setDirections(response);
            // this.polyline = new window.google.maps.Polyline({
            //   path: [],
            //   strokeColor: '#0000FF',
            //   strokeWeight: 3
            // });

            this.directionsDisplay.setDirections(response);
            this.directionsDisplay.setMap(this.map);
            this.routing_button.textContent = "close";
          } else {
            window.alert("Directions request failed due to " + status);
            this.routing_button.style.display = "none";
          }
        }
      );
    }
  },
  calc_distance: function (lat1, lon1, lat2, lon2) {
    let R = 6371e3; // metres
    let a1 = (lat1 * Math.PI) / 180;
    let a2 = (lat2 * Math.PI) / 180;
    let aa = ((lat2 - lat1) * Math.PI) / 180;
    let bb = ((lon2 - lon1) * Math.PI) / 180;

    let c =
      Math.sin(aa / 2) * Math.sin(aa / 2) +
      Math.cos(a1) * Math.cos(a2) * Math.sin(bb / 2) * Math.sin(bb / 2);
    let d = 2 * Math.atan2(Math.sqrt(c), Math.sqrt(1 - c));
    return R * d;
  },
  add_marker: function (markerloc = [], ICON = {}, clusterimag = "/cluster/m") {
    let mapmark;
    mapmark = markerloc.map((that) => {
      let marker = new window.google.maps.Marker({
        position: { lat: that.lat, lng: that.lng },
        map: this.map,
        icon: ICON,
      });
      this.geocoder(marker);
      return marker;
    });
    if (mapmark.length) return { mapmark };
    let clustererer = new MarkerClusterer(this.map, mapmark, {
      imagePath: clusterimag,
      textColor: "white",
      ignoreHidden: true,
    });
    return { mapmark, clustererer };
  },
  change_route_mode: function (mode, transitOptions) {
    if (this.end_of_rout) {
      this.mode = mode;
      if (this.mode === "Transit") {
        this.routing(this.end_of_rout.position, transitOptions);
      } else {
        this.routing(this.end_of_rout.position);
      }
    } else {
      console.log("give me sth to work with");
    }
  },
  clickhandler: function (mapsMouseEvent) {
    // if (this.end_of_rout === this.marker) { this.end_of_rout = undefined }
    if (this.marker) {
      this.marker.setMap(null);
      // {
      //   /*this.markerClusterer[type].removeMarker(this.marker);*/
      // }
    }
    // if (this.directionsDisplay.getMap() === null || this.directionsDisplay.getMap() === undefined) { this.routing_button.style.display = 'none' }
    // user selected marker position
    this.usmp = mapsMouseEvent.latLng;
    this.marker = new window.google.maps.Marker({
      position: mapsMouseEvent.latLng,
      map: this.map,
      // icon: './marker_img/',
    });
    this.geocoder(this.marker);
    // this.markerClusterer[type].addMarker(this.marker)
  },
};
export default map_maker;
