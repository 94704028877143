import React from 'react';
import styles from './Modal.module.css';
import { AiOutlineClose as CloseIcon } from 'react-icons/ai';
import styled from '@emotion/styled';
import useCloseOnBack from "../../../hooks/useCloseOnBack";
import IconButton from '@mui/material/IconButton';

const Main = styled.div<{ width: number, maxWidth: number }>`
  width: ${props => props.width ? props.width + '%' : 'fit-content'};
  max-width: ${({ maxWidth }) => maxWidth ? maxWidth + 'px' : '100%'};
`;

interface Props {
  isOpen: boolean;
  toggle: () => void;
  width: number; // this should be in percent
  className: string;
  style: React.CSSProperties;
  maxWidth: number; // should be in px
  dontUseCloseOnBack: boolean;
  children:React.ReactNode;
}

/**
 * <b/>ALL OF THE PROPS ARE OPTIONAL
 * @param width must be in percent
 * @param maxWidth must be in pixels
 * */
export const Modal: React.FC<Partial<Props>> = (props) => {
  const { children, isOpen = true, toggle, width, maxWidth, className = '', style = {}, dontUseCloseOnBack = false } = props;

  useCloseOnBack(isOpen, toggle ? toggle : () => { }, { disable: dontUseCloseOnBack, id: 'Modal!!!' });

  return (
    <div className={` ${styles.container} ${isOpen ? styles.showModal : ''}`} data-testid='modal-container'>
      <Main className={` ${styles.main} ${className}`} style={style} width={width} maxWidth={maxWidth} data-testid='modal'>
        {
          !!props.toggle
          &&
          <IconButton onClick={toggle} className={styles.close}>
            <CloseIcon color="#4a4a4a" />
          </IconButton>
        }
        {children}
      </Main>
      <div className={`${styles.backdrop} ${isOpen ? styles.showBackdrop : ''}`} onClick={() => toggle?.()} data-testid='backdrop' />
    </div>
  );
};
