import merge from 'lodash.merge'
import { useHistory } from 'react-router-dom'
import {FetchSyncApp, SetStateByKey} from "./ConfigProvider/ConfigProvider";
import ConfigureRefreshFetch from './RefreshFetch/configureRefreshFetch'
import {getCookie, removeCookies, setCookies} from 'cookies-next'
import FetchJSON from './RefreshFetch/FetchJSON'
import { LoginActions, LogoutActions } from './LoginProvider'
import { LoginUPassState , VerPrefixSetState , PasswordSetState , UsernameSetState ,  ForgetPassSetState , LoadingSetState , LoadingGoogleSetState , LoadingFacebookSetState , setDialogDigitalCodeSetState , setDialogPasswordSetState} from './UserNameAndPassword'
import {ChangePasswordSetState} from './ChangePassword/ChangePassword'
import { CounterDown } from './CounterDown'
import ChechInfoUser from './ChangeInfo/CheckInfoUser'
import {useLang} from './langProvider/LangProvider'
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";


const TOKEN_NAME = 'Token'
const REFRESH_TOKEN_NAME = 'refreshToken'
// state of login

// token in storage
const retrieveToken = () => getCookie(TOKEN_NAME)
const saveToken = (token,expires=60*60*24*30) =>
  setCookies(TOKEN_NAME, token,
    { expires: new Date().addSeconds(expires),
      ...(process.env.NODE_ENV==='production'&&{secure:true})
    })
const clearToken = () => removeCookies(TOKEN_NAME)
//

// refresh token in storage
const retrieveRefreshToken = () => getCookie(REFRESH_TOKEN_NAME)
const saveRefreshToken = (refreshToken,expires=365*24*60*60) =>{
  setCookies(REFRESH_TOKEN_NAME, refreshToken,
    { expires: new Date().addSeconds(expires),
      ...(process.env.NODE_ENV==='production'&&{secure:true})
    })
}
const clearRefreshToken = () => removeCookies(REFRESH_TOKEN_NAME)
//


//  list of request without token :
//  1.forget_password
//  2.login
//  3.signup
//  4.socialite_token

// fetch without token
function ForgetPassword(){
  const { username , phoneNumber , conformed } = LoginUPassState()
  const {setVerPrefixMethod} = VerPrefixSetState()
  const { setForgetPassMethod } = ForgetPassSetState()
  const { loadingMethod } = LoadingSetState()
  const fetchJSON = FetchJSON()
  const history = useHistory()
  const Lang = useLang() 
  
  let signUpBody = 
  conformed === 'email' 
  ? {email:username,conformed_by:conformed} 
  : conformed === 'phone_number' 
  ? {phone_number:phoneNumber,conformed_by:conformed} 
  : ''
  
  
  const forgetPassword = () => {
    loadingMethod(1)
    return fetchJSON(`${process.env.REACT_APP_BASE_URL}/api/user/forget_password`, {
      method: 'POST',
      headers:{
        lang:Lang
      },
      body: JSON.stringify(signUpBody)
    })
    .then(response => {
      // 
        if(response.response.status === 201){
          setVerPrefixMethod(response.body.data?.verification_prefix);   
          history.push('/code')
        }else if(response.response.status === 200){
          history.push('/password')
        }
        //
        setForgetPassMethod(1)
        loadingMethod(0)

      })
      .catch(error => {   

        loadingMethod(0)

      })
  }
  return { forgetPassword }
}

// SignUp
function SignUp(){
  const { username , phoneNumber , conformed, recaptcha } = LoginUPassState()
  const {setVerPrefixMethod} = VerPrefixSetState()
  const { setForgetPassMethod } = ForgetPassSetState()
  const { loadingMethod } = LoadingSetState()
  const {setChangePrevPassIsSetMethod} = ChangePasswordSetState()
  const {dialogDigitalMethod} = setDialogDigitalCodeSetState()
  const {dialogPasswordMethod} = setDialogPasswordSetState()
  const fetchJSON = FetchJSON()
  const history = useHistory()
  const Lang = useLang() 
  
  let signUpBody = 
  conformed === 'email' 
  ? {email:username,"g-recaptcha-response":recaptcha}
  : conformed === 'phone_number' 
  ? {phone_number:phoneNumber,"g-recaptcha-response":recaptcha}
  : ''
  
  const signUp = () => {
    loadingMethod(1)
    
    return fetchJSON(`${process.env.REACT_APP_BASE_URL}/api/user/signup`, {
      method: 'POST',
      headers:{
          lang:Lang
      },
      body: JSON.stringify(signUpBody)
    } , true , false )
      .then(response => {

        // 
        if(response.response.status === 201){
          // history.push('/code')
          setVerPrefixMethod(response.body.data?.verification_prefix);
          dialogDigitalMethod(true)
          setChangePrevPassIsSetMethod(0)

        }else if(response.response.status === 200){

          history.push('/password')
          dialogPasswordMethod(true)
          setChangePrevPassIsSetMethod(1)
          
        }
        setForgetPassMethod(0)
        loadingMethod(0)
      })
      .catch((error) => {

        loadingMethod(0)

      })

  }

  return { signUp }
}

// resend code
function ResendCode(){
  const { username , phoneNumber , conformed } = LoginUPassState()
  const { resetTime } = CounterDown()
  const { loadingMethod } = LoadingSetState()
  const {setVerPrefixMethod} = VerPrefixSetState()
  const fetchJSON = FetchJSON()
  const Lang = useLang() 
  
  let signUpBody = 
  conformed === 'email' 
  ? {email:username} 
  : conformed === 'phone_number' 
  ? {phone_number:phoneNumber} 
  : ''
  
  const resendCode = () => {
    loadingMethod(1)
    return fetchJSON(`${process.env.REACT_APP_BASE_URL}/api/user/signup`, {
      method: 'POST',
      headers:{
        lang:Lang
      },
      body: JSON.stringify(signUpBody)
    })
      .then(response => {
        // 
        setVerPrefixMethod(response.body.data.verification_prefix)
        resetTime()
        loadingMethod(0)
        //      
      })
      .catch(error => {

        loadingMethod(0)

      })
  }
  return { resendCode }
}

// socialite
function Socialite(){
  const history = useHistory()
  const {Login} = LoginActions();
  const { loadingMethod } = LoadingSetState()
  const { loadingGoogleMethod } = LoadingGoogleSetState()
  const { loadingFacebookMethod } = LoadingFacebookSetState()
  const fetchJSON = FetchJSON()
  const userInfoApi = SetStateByKey()
  const chechInfoUser = ChechInfoUser()
  const Lang = useLang() 
  
  const socialite = (driver , accessToken) => {
    if(driver === 'google'){
      loadingGoogleMethod(1)
    }else if(driver === 'facebook'){
      loadingFacebookMethod(1)
    }
    loadingMethod(1)
    return fetchJSON(`${process.env.REACT_APP_BASE_URL}/api/user/socialite_login`, {
      method: 'POST',
      headers:{
        lang:Lang
      },
      body: JSON.stringify({driver,socialite_token:accessToken})
    })
    .then(response => {

      saveToken(response.body.data.access_token,response.body.data?.expires_in);
      saveRefreshToken(response.body.data.refresh_token,response.body.data?.refresh_token_expires_in);
      Login()
      chechInfoUser()
      history.push('/')
      userInfoApi("user",response.body.data.user)
      loadingGoogleMethod(0)
      loadingFacebookMethod(0)
      loadingMethod(0)

    })
    .catch(error => {
      loadingGoogleMethod(0)
      loadingFacebookMethod(0)
      loadingMethod(0)
    })
  }
  return { socialite }
}

// login
function LoginApi() {
  const { username, phoneNumber , conformed} = LoginUPassState()
  const { setPasswordMethod } = PasswordSetState()
  const { setUsernameMethod } = UsernameSetState()
  const { loadingMethod } = LoadingSetState()
  const userInfoApi = SetStateByKey()
  const history = useHistory()
  const {Login} = LoginActions()
  const fetchJSON = FetchJSON()
  const chechInfoUser = ChechInfoUser()
  const Lang = useLang()
  const {fetchSyncApp} = FetchSyncApp()

  const usernameLogin = 
    conformed === 'email'
    ? username 
    : phoneNumber

  const loginApiChild = (password) => {
    loadingMethod(1)
    return fetchJSON(`${process.env.REACT_APP_BASE_URL}/api/user/login`, {
      method: 'POST',
      headers:{
        lang:Lang
      },
      body: JSON.stringify({username:usernameLogin,password:password})
    })
      .then(response => {

        if(localStorage?.intro !== "intruth"){
            localStorage.intro = "intruth";
        }

        saveToken(response.body.data.access_token,response.body.data?.expires_in);
        saveRefreshToken(response.body.data.refresh_token,response.body.data?.refresh_token_expires_in);
        Login()
        history.push('/')
        userInfoApi("user",response.body.data.user)
        chechInfoUser()
        setUsernameMethod('')
        setPasswordMethod('')
        loadingMethod(0)
        fetchSyncApp()
      })
      .catch(error => {


        if(error?.body?.message?.username){
          const message = error.body.message.username[0];
          if(message === "The username field is required."){
            history.go(-1)
            loadingMethod(0)
          }
          return
        }

       
        loadingMethod(0)
      })
  }
  return {loginApiChild}
}
// 


// Logout Api Fetcher
const LogoutApi = () => {
  const { Logout } = LogoutActions()
  const history = useHistory()

  const logoutApiChild = () => {

    return fetchJSONWithToken(`${process.env.REACT_APP_BASE_URL}/api/user/logout`, {
      method: 'POST'
    })
      .then((res) => {
        clearToken();
        clearRefreshToken();
        Logout()

      })
      // it's optional and alternative is history.push('/intro') inside first .then()
      .then(() => {
        if(isMobile){
          history.push('/login')
        }
      })
      .catch(error => {
        Logout()
        if(isMobile){
          history.push('/login')
        }
        clearToken();
        clearRefreshToken();
      }).then()
  }
  return { logoutApiChild }
}

// fetch with token
const fetchJSONWithToken = (url, options , showError , showSuccess) => {
  const fetchJSON = FetchJSON()
  const token = retrieveToken()
  let optionsWithToken = options
  if (token != null) {
    optionsWithToken = merge({}, options, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }

  return fetchJSON(url, optionsWithToken , showError , showSuccess)
}

// If this function returned true, refresh token submit to server and get token 
const shouldRefreshToken = error => {
  // const login = LoginState()
  return error?.response?.status === 401 && retrieveRefreshToken()
}

const RefreshToken = () => {
  return fetchJSONWithToken(`${process.env.REACT_APP_BASE_URL}/api/user/refresh_token`, {
    method: 'POST',
    body:JSON.stringify({refresh_token:retrieveRefreshToken()})
  }, false)
    .then(res => {
      saveToken(res.body.data.access_token,res.body.data?.expires_in);
      saveRefreshToken(res.body.data.refresh_token,res.body.data?.refresh_token_expires_in);
    })
    .catch(error => {
      if(typeof error === 'undefined'){
        return
      }
      // Clear token and continue with the Promise catch chain
      clearToken()
      throw error
    })
}

const Fetch = () => {

  const configureRefreshFetch = ConfigureRefreshFetch()

  const fetch = configureRefreshFetch({
    fetch: fetchJSONWithToken,
    shouldRefreshToken,
    RefreshToken,
  })
  return fetch
}

export {
  Fetch,
  LoginApi,
  LogoutApi,
  SignUp,
  ForgetPassword,
  ResendCode,
  Socialite,
  retrieveToken
}
