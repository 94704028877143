import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './components.module.css'
import About from "./About";
import {
    ChangeCompanyAboutHandler,
    ChangeCompanyLocationHandler,
    ChangeInfoState
} from "../../../Provider/ChangeInfo/ChangeInfo";
import Map from "../../Map/ProblemMap";
import {useConfig} from "../../../Provider/ConfigProvider/ConfigProvider";
import {useTranslation} from "react-i18next";

export default function MainMenu({categories}: any) {
    const [expanded, setExpanded] = React.useState<string | false>('panel1');
    const {changeCompanyAboutHandler} = ChangeCompanyAboutHandler()
    const {changeCompanyLocationHandler} = ChangeCompanyLocationHandler()
    const syncApp = useConfig()
    const {companyAbout, companyLocation} = ChangeInfoState();
    const {t: commonT} = useTranslation('common');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (
        <div style={{width: '100%'}}>
            <Accordion expanded={expanded === 'panel1'} className={expanded === 'panel1' ? styles.activePanel : null}
                       onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                >
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <Typography variant='body1'>
                            {commonT('aboutUsLabel')}
                        </Typography>
                        <Typography sx={{color: 'text.secondary'}}>{commonT('aboutUsDescLabel')}</Typography>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <About categories={categories} setExpanded={setExpanded}/>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} className={expanded === 'panel2' ? styles.activePanel : null}
                       onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                >
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <Typography variant='body1'>
                            {commonT('companyAboutLabel')}
                        </Typography>
                        <Typography sx={{color: 'text.secondary'}}>{commonT('companyAboutDescLabel')}</Typography>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <textarea value={companyAbout || syncApp?.user?.supplier_info?.about}
                              onChange={e => changeCompanyAboutHandler(e.target.value)}
                              placeholder={commonT('companyAboutDescLabel')} className={styles.textArea}/>
                </AccordionDetails>
            </Accordion>
            <Accordion className={expanded === 'panel3' ? styles.activePanel : null} expanded={expanded === 'panel3'}
                       onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                >
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <Typography variant='body1'>
                            {commonT('companyLocationLabel')}
                        </Typography>
                        <Typography sx={{color: 'text.secondary'}}> {commonT('companyLocationDescLabel')}</Typography>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={styles.mapContainer}>
                        <Map setlocation={changeCompanyLocationHandler}
                             setlocationname={(value: any) => console.log('value+++++++++++')}/>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
