/* eslint-disable array-callback-return */
import merge from 'lodash.merge'
import {toast} from 'react-toastify'
import {useTranslation} from "react-i18next";
import {useLanguage} from '../langProvider/LangProvider'

function FetchJSON() {
  const customId = "laskdjflasjdfljasldfjlasdjfljl"
  // const {messages} = useLanguage()
  // const {t} = useTranslation('messages');
  // console.log(messages)
  // console.log('net')
  // const messageNetwork = t("networkError")
  const notify = () => {
    toast("networkError", {
      // toastId: customId,
      type:"error"
    });
  }
  const fetchJSON = async (url, options, showError=null, showSuccess=null) => {
    const fetchOptions = typeof (options.body) === 'undefined'
      ? options
      : merge(
        {
          headers: {
            'Content-Type': 'application/json'
          }
        },
        options
      )
    const DisplayError = showError ?? true
    const DisplaySuccess = showSuccess ?? true
    window.date1 = 1;

    return fetch(url, fetchOptions)
      .then(response => {
        return getResponseBody(response).then(body => ({
          response,
          body
        }))
      })
      .then((response) => checkStatus(response, DisplaySuccess))
      .catch(err => {

        if (typeof err === 'undefined') {
          toast.error('Unexpected Error!')
          return
        }

        if (err?.body?.message?.length !== 0 && err?.body?.message && DisplayError && err?.response?.status !== 401) {
          if (typeof err.body.message !== 'string') {
            Object.values(err.body.message).map(msg => {
              toast.error(msg[0])
            })
          } else {
            toast.error(err.body.message)
          }
        }


        if (err?.message === 'Failed to fetch') {
          // divided and rounded millisecond . Date.now() returns the number of milliseconds since January 1, 1970.
          // const date2 = Math.round(Date.now()/1000)

          // if((date2 - window.date1) > 4){
          //   toast.error('مشکلی پیش امده لطفا پس از بررسی اینترنت مجددا تلاش کنید.')
          //   window.date1 = Math.round(Date.now()/1000)
          // }
          notify()
        } else {
          throw err
        }

      })

  }
  return fetchJSON
}

// JSON parse
const getResponseBody = response => {
  const contentType = response.headers.get('content-type')
  return contentType && contentType.indexOf('json') >= 0
    ? response.clone().text().then(tryParseJSON)
    : response.clone().text()
}

const tryParseJSON = json => {
  if (!json) {
    return null
  }

  try {
    return JSON.parse(json)
  } catch (e) {
    throw new Error(`Failed to parse unexpected JSON response: ${json}`)
  }
}
// 

// manage Error 
function ResponseError(status, response, body) {
  this.name = 'ResponseError'
  this.status = status
  this.response = response
  this.body = body
}


const checkStatus = ({response, body}, showSuccess) => {
  if (response.ok) {
    if ((response?.status === 201 || response?.status === 202 || response?.status === 206) && showSuccess) {
      toast.success(body.message)
    }
    return {response, body}
  } else {
    throw new ResponseError(response.status, response, body)
  }
}
// 

export default FetchJSON
