import {Grid, TextField} from "@mui/material";
import styles from './components.module.css'
import Typography from "@mui/material/Typography";
import {
    ChangeCompanyCategoryHandler,
    ChangeCompanyEmailHandler,
    ChangeCompanyNameHandler,
    ChangeInfoState,
} from "../../../Provider/ChangeInfo/ChangeInfo";
import Phone from "./Phone";
import {useConfig} from "../../../Provider/ConfigProvider/ConfigProvider";
import {useTranslation} from "react-i18next";
import GroupSelect from "./GroupSelect";
import React from "react";

export default function About({setExpanded, categories}: any) {

    const {changeCompanyNameHandler} = ChangeCompanyNameHandler();
    const {changeCompanyCategoryHandler} = ChangeCompanyCategoryHandler();
    const {changeCompanyEmailHandler} = ChangeCompanyEmailHandler();
    const {companyName, companyCategory, companyEmail} = ChangeInfoState();
    const syncApp = useConfig()
    const {t: commonT} = useTranslation('common');

    const handleChangeCompany = (category: any) => {
        if (category) {
            changeCompanyCategoryHandler(category?.id)
        }
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={6}
                  sx={{display: 'flex', flexDirection: 'column', gap: '1em'}}
            >
                <Typography variant='body1'>
                    {commonT('companyNameLabel')} <span className={styles.star}>*</span>
                </Typography>
                <TextField value={companyName || syncApp?.user?.supplier_info?.company_name}
                           onChange={e => changeCompanyNameHandler(e.target.value)}
                           placeholder={commonT('companyNameDesc')}/>
            </Grid>
            <Grid item xs={6}
                  sx={{display: 'flex', flexDirection: 'column', gap: '1em'}}
            >
                <Typography variant='body1'>
                    {commonT('companyCategoryLabel')} <span className={styles.star}>*</span>
                </Typography>
                <GroupSelect selectedItem={companyCategory || syncApp?.user?.supplier_info?.category_id}
                             items={categories} afterSelectHandler={(value: any) => handleChangeCompany(value)}/>
            </Grid>
            <Grid item xs={6}
                  sx={{display: 'flex', flexDirection: 'column', gap: '1em'}}
            >
                <Typography variant='body1'>
                    {commonT('companyEmailLabel')}
                </Typography>
                <TextField value={companyEmail || syncApp?.user?.supplier_info?.email}
                           onChange={e => changeCompanyEmailHandler(e.target.value)} type='email'
                           placeholder={commonT('companyEmailDesc')}/>
            </Grid>
            <Grid item xs={6}
                  sx={{display: 'flex', flexDirection: 'column', gap: '1em'}}
            >
                <Typography variant='body1'>
                    {commonT('companyPhoneLabel')} <span className={styles.star}>*</span>
                </Typography>
                <Phone setExpanded={setExpanded}/>
                {/*<TextField type='phone' placeholder='Enter your company phone number'/>*/}
            </Grid>
        </Grid>
    )
}
