import React from 'react'
import styles from './Aside.module.css'

const Handle = ({handleCollapsedChange,collapsed}) => {
  return(
    <button type='button' className={styles.handle} >
      <span></span>
    </button>
  )
}
export default Handle
