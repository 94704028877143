import EagleEyeLoading from '../../Assets/loading.gif';
import styles from './LoadingImg.module.css';

const LoadingImg = (): JSX.Element => {
  return (
    <div className={styles.main}>
      <img src={EagleEyeLoading} alt='loading' className={styles.img} />
    </div>
  );
};

export default LoadingImg;
